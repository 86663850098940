import { INFORMATION } from 'store/user/const';
import {
  DISPATCHERS,
  CREATE_DISPATCHER,
} from './const';

const initialState = {
  list: {
    byId: {},
    all: [],
    meta: {
      errorMessage: '',
      requesting: false,
    },
  },
  createDispatcher: {
    created: false,
    meta: {
      errorMessage: '',
      requesting: false,
    },
  },
};

function dispatchersReducer(state = initialState, action) {
  switch (action.type) {
    case DISPATCHERS.REQUEST: {
      return {
        ...state,
        list: {
          ...state.list,
          meta: {
            ...state.list.meta,
            errorMessage: '',
            requesting: true,
          },
        },
      };
    }

    case DISPATCHERS.RECEIVE: {
      const { data: dispatchers } = action.response;
      return {
        ...state,
        list: {
          ...state.list,
          byId: dispatchers.byId,
          all: dispatchers.all,
          meta: initialState.meta,
        },
      };
    }

    case DISPATCHERS.REJECT: {
      return {
        ...state,
        dispatchers: {
          ...initialState.list,
          meta: {
            ...initialState.list.meta,
            errorMessage: action.error,
          },
        },
      };
    }

    case CREATE_DISPATCHER.REQUEST: {
      return {
        ...state,
        createDispatcher: {
          ...state.createDispatcher,
          created: false,
          meta: {
            ...state.createDispatcher.meta,
            errorMessage: '',
            requesting: true,
          },
        },
      };
    }

    case CREATE_DISPATCHER.RECEIVE: {
      return {
        ...state,
        createDispatcher: {
          ...state.createDispatcher,
          created: true,
          meta: initialState.createDispatcher.meta,
        },
      };
    }

    case CREATE_DISPATCHER.REJECT: {
      return {
        ...state,
        createDispatcher: {
          ...state.createDispatcher,
          created: false,
          meta: {
            ...initialState.createDispatcher.meta,
            errorMessage: action.error || 'Failed to create dispatcher',
          },
        },
      };
    }

    case CREATE_DISPATCHER.CLEAR: {
      return {
        ...state,
        createDispatcher: initialState.createDispatcher,
      };
    }

    case INFORMATION.CLEAR: {
      return initialState;
    }

    default:
      return state;
  }
}

export default dispatchersReducer;
