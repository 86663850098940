import {
  configureActions,
  checkError,
} from 'store/helpers';

import {
  getDispatchers,
  postDispatcher,
  putDispatcher,
  deleteDispatcher,
} from 'lib/api/dispatchers';

import {
  DISPATCHERS,
  CREATE_DISPATCHER,
  UPDATE_DISPATCHER,
  DELETE_DISPATCHER,
} from './const';

const dispatchersActions = configureActions(DISPATCHERS);
export const fetchDispatchers = () => async (dispatch) => {
  dispatch(dispatchersActions.request());
  const response = await getDispatchers();
  if (checkError(dispatch, response, dispatchersActions.reject)) return 'fail';
  dispatch(dispatchersActions.receive(response));
  return 'success';
};

const createDispatcherActions = configureActions(CREATE_DISPATCHER);
export const createDispatcher = dispatcher => async (dispatch) => {
  dispatch(createDispatcherActions.request());
  const response = await postDispatcher(dispatcher);
  if (checkError(dispatch, response, createDispatcherActions.reject)) return 'fail';
  dispatch(createDispatcherActions.receive(response));
  dispatch(createDispatcherActions.clear());
  return 'success';
};

const updateDispatcherActions = configureActions(UPDATE_DISPATCHER);
export const updateDispatcher = dispatcher => async (dispatch) => {
  dispatch(updateDispatcherActions.request());
  const response = await putDispatcher(dispatcher);
  if (checkError(dispatch, response, updateDispatcherActions.reject)) return 'fail';
  dispatch(updateDispatcherActions.receive(response));
  return 'success';
};

const deleteDispatcherActions = configureActions(DELETE_DISPATCHER);
export const disableDispatcher = dispatcherGuid => async (dispatch) => {
  dispatch(deleteDispatcherActions.request());
  const response = await deleteDispatcher(dispatcherGuid);
  if (checkError(dispatch, response, deleteDispatcherActions.reject)) return 'fail';
  dispatch(deleteDispatcherActions.receive(response));
  return 'success';
};
