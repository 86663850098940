import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';

import { Form } from 'rmwc-components';
import User from 'lib/user';

import { arrayHelpers, validationHelpers } from 'rmwc-helpers';
const { findArrayDuplicates } = arrayHelpers;
const { isValidEmail, isValidPhone } = validationHelpers;

import styles from './EnterpriseProperties.module.css';

class EnterpriseProperties extends Component {
  constructor(props) {
    super(props);

    this.submitFormData = this.submitFormData.bind(this);
    this.validateFormData = this.validateFormData.bind(this);
    this.submitIntegrationFormData = this.submitIntegrationFormData.bind(this);
  }

  componentDidMount() {
    const { fetchEnterpriseProperties } = this.props;
    fetchEnterpriseProperties();
  }

  getFormData() {
    const { properties } = this.props;
    const formData = {
      section_AdminEmail: {
        title: 'General',
        fields: {
          adminEmail: {
            value: properties.adminEmail,
            label: 'Admin Email',
          },
        },
      },
      section_AutomaticForwarding: {
        title: 'Automatic Alert Forwarding',
        fields: {
          alertForwardingEnabled: {
            value: properties.alertForwarding,
            label: 'Enabled',
            type: 'checkbox',
          },
          emailAddresses: {
            value: (properties.alertForwardingEmails || []).filter(Boolean),
            classname: styles.emailAddresses,
            label: 'Emails',
            type: 'inputlist',
            size: 'half_width',
            config: {
              insert: false,
            },
          },
          phoneNumbers: {
            value: (properties.alertForwardingSms || []).filter(Boolean),
            classname: styles.phoneNumbers,
            label: 'Phone Numbers With Country Code (example: +18005551234)',
            type: 'inputlist',
            size: 'half_width',
            config: {
              insert: false,
            },
          },
        },
      },
    };
    return formData;
  }

  getIntegrationFormData() {
    const formData = {
      integrationType: {
        value: '',
        label: 'Integration Type',
        disabled: true,
      },
      apiBaseUrl: {
        value: '',
        label: 'API Base URL',
        disabled: true,
      },
      apiPropertyCode: {
        value: '',
        label: 'API Property Code',
        disabled: true,
      },
    };
    return formData;
  }

  submitFormData(data) {
    const { updateEnterpriseProperties } = this.props;
    return updateEnterpriseProperties(data);
  }

  submitIntegrationFormData(data) {
  }

  validateFormData(data) {
    const {
      adminEmail = '',
      phoneNumbers = [],
      emailAddresses = [],
    } = data;
    const errors = {};

    if (adminEmail && !isValidEmail(adminEmail)) {
      errors.adminEmail = 'Please enter a valid email address';
    }

    if (phoneNumbers && phoneNumbers.length) {
      phoneNumbers.forEach((phone, index) => {
        if (phone && !isValidPhone(phone)) {
          errors.phoneNumbers = errors.phoneNumbers || {};
          errors.phoneNumbers[index] = 'Invalid phone number format, see example above';
        }

        if (phone === '') {
          errors.phoneNumbers = errors.phoneNumbers || {};
          errors.phoneNumbers[index] = 'Phone number cannot be blank';
        }
      });

      const duplicatePhoneNumbers = findArrayDuplicates(phoneNumbers);
      if (duplicatePhoneNumbers.length) {
        errors.phoneNumbers = errors.phoneNumbers || {};
        duplicatePhoneNumbers.forEach((item) => {
          errors.phoneNumbers[item] = 'Duplicate phone number';
        });
      }
    }

    if (emailAddresses && emailAddresses.length) {
      emailAddresses.forEach((email, index) => {
        if (email && !isValidEmail(email)) {
          errors.emailAddresses = errors.emailAddresses || {};
          errors.emailAddresses[index] = 'Please enter a valid email address';
        }

        if (email === '') {
          errors.emailAddresses = errors.emailAddresses || {};
          errors.emailAddresses[index] = 'Email address cannot be blank';
        }
      });

      const duplicateEmailAddresses = findArrayDuplicates(emailAddresses, {
        ignoreCasing: true,
      });
      if (duplicateEmailAddresses.length) {
        errors.emailAddresses = errors.emailAddresses || {};
        duplicateEmailAddresses.forEach((item) => {
          errors.emailAddresses[item] = 'Duplicate email address';
        });
      }
    }

    return errors;
  }

  renderIntegrations() {
    const { integrations } = this.props;

    const integrationTypes = {
      'hotsos': {
        title: 'Amadeus HotSOS',
        urlKey: 'apiUrl',
        propertyKey: 'username',
      },
      'knowcross': {
        title: 'Knowcross',
        urlKey: 'apiUrl',
        propertyKey: 'propertyCode',
      },
      'pineapple': {
        title: 'Pineapple Custom',
        urlKey: 'apiUrl',
        propertyKey: 'username',
      },
    };

    const integrationNodes = [];
    Object.keys(integrationTypes).forEach((integrationKey) => {
      if (!integrations[integrationKey]) return;
      const integration = integrations[integrationKey];
      const integrationType = integrationTypes[integrationKey];
      const integrationNode = (
        <div className={styles.integration}>
          <div>{integrationType.title}</div>
          <div>API Base URL: {integration[integrationType.urlKey]}</div>
          <div>API Property Code: {integration[integrationType.propertyKey]}</div>
        </div>
      );
      integrationNodes.push(integrationNode);
    });

    if (!integrationNodes.length) return null;

    return (
      <Fragment>
        <div className={styles.sectionTitle}>Integrations</div>
        {integrationNodes}
      </Fragment>
    );
  }

  render() {
    return (
      <Fragment>
        <Form
          fieldInfo={this.getFormData()}
          validate={this.validateFormData}
          onSubmit={this.submitFormData}
          disabled={User.isNotAdmin()}
        />
        {this.renderIntegrations()}
        {/* <Form
          name="3rd Party Integrations"
          fieldInfo={this.getIntegrationFormData()}
          onSubmit={this.submitIntegrationFormData}
          submitLabel="Submit"
        /> */}
      </Fragment>
    );
  }
}

EnterpriseProperties.propTypes = {
  properties: propTypes.object,
  integrations: propTypes.object,

  fetchEnterpriseProperties: propTypes.func.isRequired,
  updateEnterpriseProperties: propTypes.func.isRequired,
};

EnterpriseProperties.defaultProps = {
  properties: {},
  integrations: {},
};

export default EnterpriseProperties;
