import { connect } from 'react-redux';

import integrationsSelector, {
  integrationsMetaSelector,
} from 'store/enterprise/selectors/integrationsSelector';
import {
  lteDevicesListSelector,
  lteDevicesRequestingSelector,
} from 'store/devices/external/selectors/lteDevicesSelector';
import {
  cellularListSelector,
  cellularRequestingSelector,
} from 'store/devices/cellular/selectors/cellularSelector';

import {
  fetchEnterpriseIntegrations,
} from 'store/enterprise/thunks';
import {
  fetchEnterpriseLTEDevices,
} from 'store/devices/external/thunks';
import {
  fetchCellular,
} from 'store/devices/cellular/thunks';

import {
  isProdEnvSelector,
} from 'store/general/selectors/generalSelector';

import LTEDevices from './components/LTEDevices';

const mapStateToProps = state => ({
  integrations: integrationsSelector(state),
  integrationsMeta: integrationsMetaSelector(state),
  lteDevices: lteDevicesListSelector(state),
  lteRequesting: lteDevicesRequestingSelector(state),
  cellularDevices: cellularListSelector(state),
  cellularRequesting: cellularRequestingSelector(state),
  isProdEnv: isProdEnvSelector(state),
});

const mapDispatchToProps = {
  fetchEnterpriseIntegrations,
  fetchEnterpriseLTEDevices,
  fetchCellular,
};

export default connect(mapStateToProps, mapDispatchToProps)(LTEDevices);
