import Store from 'store/store';

import {
  requestOptions,
  makeRequest,
} from './helper';

async function getEnterpriseActiveAlerts(opts = {}) {
  const options = requestOptions('GET', {
    headers: {
      alertstatus: 'active',
      etag: opts.etag || '',
    },
  });
  return makeRequest('alerts/active', options);
}

async function getEnterpriseInactiveAlerts(opts = {}) {
  const options = requestOptions('GET', {
    headers: {
      alertstatus: 'inactive',
      etag: opts.etag || '',
    },
  });
  return makeRequest('alerts/inactive', options);
}

async function putEnterpriseDisableAlert(alertId, disableType) {
  const options = requestOptions('PUT', {
    body: {
      alert_id: alertId,
      enterprise_guid: Store.enterpriseId,
      status: disableType,
    },
  });
  return makeRequest('alerts', options);
}

export {
  getEnterpriseActiveAlerts,
  getEnterpriseInactiveAlerts,
  putEnterpriseDisableAlert,
};
