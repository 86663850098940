import React, { Component } from 'react';
import propTypes from 'prop-types';
import Store from 'store/store';

import {
  Button,
  TableInfo,
  AlertImportantInfo,
} from 'rmwc-components';
import AlertMap from 'modules/components/AlertMap';
import Notes from 'modules/components/Notes';
import SectionHeader from 'modules/components/SectionHeader';
import { Locale, Logger } from 'rmwc-libraries';
import { alertHelpers, timeHelpers } from 'rmwc-helpers';
const { formatLatLong, formatCustomFields } = alertHelpers;
const { formatDate } = timeHelpers;

import Settings from 'lib/settings';
import User from 'lib/user';

import styles from './AlertDetails.module.css';

class AlertDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alert: null,
    };

    this.setAsFalseAlert = this.setAsFalseAlert.bind(this);
  }

  componentDidMount() {
    const {
      uuid,
      allAlerts = {},
      fetchEnterpriseInactiveAlerts,
    } = this.props;

    const alert = allAlerts[uuid];

    this.setState({ alert });

    if (!alert) fetchEnterpriseInactiveAlerts();
  }

  shouldComponentUpdate(nextProps) {
    const {
      uuid,
      fetchEnterpriseInactiveAlerts,
    } = this.props;
    const {
      allAlerts: nextAllAlerts = {},
    } = nextProps;
    const {
      alert,
    } = this.state;

    const nextAlert = nextAllAlerts[uuid];

    // This scenario will happen with a refresh on the detail page. This will
    // be called after inactive alerts have been fetched
    if (!alert && nextAlert) {
      this.setState({ alert: nextAlert });
      return true;
    }

    // The current alert doesn't exist anymore in the store. This happens when an active alert goes inactive.
    // To get the alert details again, we need to fetch inactive alerts.
    if (alert && alert.status === 'active' && !nextAlert) {
      fetchEnterpriseInactiveAlerts();
      return false;
    }

    // Go to the Alerts History detail page when the alert is valid and no longer active
    if (alert && alert.status === 'active' && nextAlert && nextAlert.status !== 'active') {
      Store.history.push(`/alerts/history/${uuid}`);
      return false;
    }

    return true;
  }

  setAsFalseAlert() {
    const { fetchDisableAsFalseAlarmAlert } = this.props;
    const { alert } = this.state;
    fetchDisableAsFalseAlarmAlert(alert.id);
  }

  render() {
    const { alert } = this.state;
    if (!alert) return <div>Alert details not available at the moment</div>;
    Logger.debug(JSON.stringify(alert, null, 2));

    const alertInfo = formatCustomFields(alert, {
      'ID': alert.id,
      'Status': (alert.status || '').replace(/_/g, ' ').toUpperCase(),
      'Date/Time': formatDate(alert.createdAt),
      'Start Address': alert.originalAddress,
      'Start Lat/Long': formatLatLong(alert, 'start'),
      'Last Known Lat/Long': formatLatLong(alert, 'lastKnown'),
    });

    return (
      <div className={styles.detailsWrapper}>
        <AlertImportantInfo alert={alert} />

        <AlertMap
          className={styles.map}
          alert={alert}
          latitude={alert.lastKnownLatitude || alert.latitude}
          longitude={alert.lastKnownLongitude || alert.longitude}
          zoomFitNewAlerts={Settings.getValue('zoomFitNewAlerts')}
          zoomOnPinClick={Settings.getValue('zoomOnPinClick')}
        />

        <SectionHeader text="Details" />
        <TableInfo className={styles.info} data={alertInfo} />

        <Button
          text={Locale.t('set_as_false_button')}
          onClick={this.setAsFalseAlert}
          color="red"
          disabled={alert.status !== 'active'}
          confirm={Locale.t('set_as_false_confirmation')}
          locked={User.isNotOperator()}
        />

        <SectionHeader text="Notes" />
        <Notes alert={alert} />
      </div>
    );
  }
}

AlertDetails.propTypes = {
  uuid: propTypes.string,
  allAlerts: propTypes.object,

  fetchEnterpriseInactiveAlerts: propTypes.func.isRequired,
  fetchDisableAsFalseAlarmAlert: propTypes.func.isRequired,
};

AlertDetails.defaultProps = {
  uuid: '',
  allAlerts: {},
};

export default AlertDetails;
