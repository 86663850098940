import {
  configureActions,
  checkError,
  checkNoUpdate,
} from 'store/helpers';

import {
  getEnterpriseCellular,
} from 'lib/api/enterprise';
import {
  cellularMetaSelector,
} from './selectors/cellularSelector';

import { CELLULAR } from './const';

const cellularActions = configureActions(CELLULAR);
export const fetchCellular = () => async (dispatch, getState) => {
  const {
    etag = '',
    requesting = false,
  } = cellularMetaSelector(getState());

  if (requesting) return;
  dispatch(cellularActions.request());
  const response = await getEnterpriseCellular();
  if (checkError(dispatch, response, cellularActions.reject)) return;
  if (checkNoUpdate(dispatch, etag, response, cellularActions.end)) return;
  dispatch(cellularActions.receive(response));
};
