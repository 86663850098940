import {
  configureActions,
  checkError,
} from 'store/helpers';

import {
  getUsers,
  putUserProfile,
  putUserExternalDeviceIds,
  deleteUser,
} from 'lib/api/users';

import {
  PHONES,
  UPDATE_PHONE,
  UPDATE_PHONE_EXTERNAL_DEVICE_IDS,
  DELETE_PHONE,
} from './const';

const phonesActions = configureActions(PHONES);
export const fetchPhones = () => async (dispatch) => {
  dispatch(phonesActions.request());
  const response = await getUsers();
  if (checkError(dispatch, response, phonesActions.reject)) return 'fail';
  dispatch(phonesActions.receive(response));
  return 'success';
};

const updatePhoneActions = configureActions(UPDATE_PHONE);
export const updatePhone = (userGuid, profile) => async (dispatch) => {
  dispatch(updatePhoneActions.request());
  const response = await putUserProfile(userGuid, profile);
  if (checkError(dispatch, response, updatePhoneActions.reject)) return 'fail';
  dispatch(updatePhoneActions.receive(response));
  return 'success';
};

const updatePhoneExternalDeviceIdsActions = configureActions(UPDATE_PHONE_EXTERNAL_DEVICE_IDS);
export const updatePhoneExternalDeviceIds = (userGuid, externalDeviceIds) => async (dispatch) => {
  dispatch(updatePhoneExternalDeviceIdsActions.request());
  const data = await putUserExternalDeviceIds(userGuid, externalDeviceIds);
  if (checkError(dispatch, data, updatePhoneExternalDeviceIdsActions.reject)) return 'fail';
  dispatch(updatePhoneExternalDeviceIdsActions.receive(data));
  return 'success';
};

const deletePhoneActions = configureActions(DELETE_PHONE);
export const removePhone = userGuid => async (dispatch) => {
  dispatch(deletePhoneActions.request());
  const response = await deleteUser(userGuid);
  if (checkError(dispatch, response, deletePhoneActions.reject)) return 'fail';
  dispatch(deletePhoneActions.receive(response));
  return 'success';
};
