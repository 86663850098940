import {
  configureActions,
  checkError,
  checkNoUpdate,
} from 'store/helpers';

import {
  getEnterpriseBeacons,
} from 'lib/api/enterprise';
import {
  beaconsMetaSelector,
} from './selectors/beaconsSelector';

import { BEACONS } from './const';

const beaconsActions = configureActions(BEACONS);
export const fetchBeacons = () => async (dispatch, getState) => {
  const {
    etag = '',
    requesting = false,
  } = beaconsMetaSelector(getState());

  if (requesting) return;
  dispatch(beaconsActions.request());
  const response = await getEnterpriseBeacons();
  if (checkError(dispatch, response, beaconsActions.reject)) return;
  if (checkNoUpdate(dispatch, etag, response, beaconsActions.end)) return;
  dispatch(beaconsActions.receive(response));
};
