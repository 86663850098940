import { connect } from 'react-redux';

import {
  activeAlertsListSelector,
} from 'store/alerts/active/selectors/activeAlertsSelector';

import {
  logoUrlSelector,
} from 'store/enterprise/selectors/enterpriseConfigSelector';

import Menu from './components/Menu';

const mapStateToProps = state => ({
  activeAlertsList: activeAlertsListSelector(state),
  logoUrl: logoUrlSelector(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
