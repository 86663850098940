import { connect } from 'react-redux';

import {
  fetchEnterpriseProperties,
  updateEnterpriseProperties,
} from 'store/enterprise/thunks';

import integrationsSelector from 'store/enterprise/selectors/integrationsSelector';
import propertiesSelector from 'store/enterprise/selectors/propertiesSelector';

import EnterpriseProperties from './components/EnterpriseProperties';

const mapStateToProps = state => ({
  properties: propertiesSelector(state),
  integrations: integrationsSelector(state),
});

const mapDispatchToProps = {
  fetchEnterpriseProperties,
  updateEnterpriseProperties,
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterpriseProperties);
