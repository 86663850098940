import { createSelector } from 'reselect';

const DEFAULT_STATE = {};

const dispatchersSelector = state => state.dispatchers || DEFAULT_STATE;

const dispatchersListSelector = createSelector(
  dispatchersSelector,
  dispatchers => dispatchers.list || {},
);

const dispatchersListAllSelector = createSelector(
  dispatchersListSelector,
  dispatchersList => dispatchersList.all.map(guid => dispatchersList.byId[guid]) || [],
);

const dispatchersListMetaSelector = createSelector(
  dispatchersListSelector,
  dispatchersList => dispatchersList.meta || {},
);

const dispatchersListRequestingSelector = createSelector(
  dispatchersListMetaSelector,
  meta => meta.requesting || false,
);

export default dispatchersSelector;

export {
  dispatchersListSelector,
  dispatchersListAllSelector,
  dispatchersListMetaSelector,
  dispatchersListRequestingSelector,
};
