import { createSelector } from 'reselect';
import dispatchersSelector from './dispatchersSelector';

const createDispatcherSelector = createSelector(
  dispatchersSelector,
  dispatchers => dispatchers.createDispatcher || {},
);

const dispatcherCreatedSelector = createSelector(
  createDispatcherSelector,
  createDispatcher => createDispatcher.created || false,
);

const createDispatcherMetaSelector = createSelector(
  createDispatcherSelector,
  createDispatcher => createDispatcher.meta || {},
);


export default createDispatcherSelector;

export {
  dispatcherCreatedSelector,
  createDispatcherMetaSelector,
};
