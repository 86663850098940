import Store from 'store/store';

import {
  requestOptions,
  makeRequest,
} from './helper';

async function getUsers() {
  return makeRequest('users', requestOptions());
}

async function getUserByGuid(userGuid) {
  const options = requestOptions('GET', {
    headers: { userGuid },
  });
  const API = `users/${userGuid}`;
  return makeRequest(API, options);
}

async function putUserProfile(userGuid, profile) {
  const formattedProfile = {
    firstname: profile.firstName,
    lastname: profile.lastName,
    mobile_phone: '', // profile.mobilePhoneNumber,
  };
  const options = requestOptions('PUT', {
    body: {
      user: formattedProfile,
      enterprise_guid: Store.enterpriseId,
      user_guid: userGuid,
    },
  });
  const API = `users/${userGuid}`;
  return makeRequest(API, options);
}

async function deleteUser(userGuid) {
  const options = requestOptions('DELETE', {
    body: {
      user_guid: userGuid,
      enterprise_guid: Store.enterpriseId,
    },
  });
  const API = `users/${userGuid}`;
  return makeRequest(API, options);
}

async function putUserExternalDeviceIds(userGuid, externalDeviceIds) {
  const formattedExternalDeviceIds = [
    externalDeviceIds.externalDeviceIdOne,
    externalDeviceIds.externalDeviceIdTwo,
    externalDeviceIds.externalDeviceIdThree,
  ];
  const options = requestOptions('PUT', {
    headers: { userGuid },
    body: {
      external_device_ids: formattedExternalDeviceIds,
      enterprise_guid: Store.enterpriseId,
    },
  });
  const API = `users/${userGuid}/external_device_ids`;
  return makeRequest(API, options);
}

export {
  getUsers,
  getUserByGuid,
  putUserProfile,
  deleteUser,
  putUserExternalDeviceIds,
};

// NOTE: These APIs probably won't be used, but keeping around for now
// Supposed to return more data but I didn't see anything additional
// async function getUsersFullSummary() {
//   const options = userListOptions('GET');
//   const API = 'fullusers';
//   return userListRequest(API, options);
// }

// async function putAssociationByEmail(email) {
//   const options = userListOptions('PUT');
//   const API = `associations/${email}`;
//   return userListRequest(API, options);
// }
