import React, { Component } from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { Switch, Route, Redirect } from 'react-router-dom';
import propTypes from 'prop-types';

import { Logger } from 'rmwc-libraries';

import cookies from 'lib/cookies';
import Notifications from 'lib/notifications';
import User from 'lib/user';

import FocusView from 'modules/components/FocusView';
import PanelView from 'modules/components/PanelView';
import UserAccess from 'modules/pages/UserAccess';
import Version from 'modules/pages/Version';
import UnhandledRoute from 'modules/pages/UnhandledRoute';

import ROUTES from '../routes';

export default class App extends Component {
  constructor(props) {
    super(props);

    this.enterpriseAlertTimer = null;
    this.enterpriseAlertTimerFrequency = 5001;

    this.NotificationsRequested = false;

    Logger.setLevel(process.env.NODE_ENV);
    Logger.debug(`Active alert polling timer is set to ${this.enterpriseAlertTimerFrequency / 1000}s`);
  }

  componentDidMount() {
    const {
      fetchBackendUrl,
      fetchVersion,
    } = this.props;

    fetchBackendUrl();
    fetchVersion();

    this.checkForCookieData();
  }

  shouldComponentUpdate(nextProps) {
    const {
      activeAlertsList: prevActive = [],
      inactiveAlertsList: prevInactive = [],
      userLoggedIn: prevUserLoggedIn,
    } = this.props;
    const {
      activeAlertsList: nextActive = [],
      inactiveAlertsList: nextInactive = [],
      userLoggedIn: nextUserLoggedIn,
    } = nextProps;

    // Make sure to trigger an update for login
    if (!prevUserLoggedIn && nextUserLoggedIn) return true;
    // If we reach this point, we can skip any updates if the alert lists haven't changed
    if (isEqual(prevActive, nextActive) && isEqual(prevInactive, nextInactive)) return false;
    return true;
  }

  componentDidUpdate(prevProps) {
    const {
      activeAlertsList: prevActiveAlerts = [],
      userRole: prevUserRole = '',
    } = prevProps;
    const {
      activeAlertsList = [],
      inactiveAlertsList = [],
      fetchEnterpriseInactiveAlerts,
      userRole = '',
      userLoggedIn,
    } = this.props;

    if (userLoggedIn) {
      // Check desktop notification permissions
      // Only "granted" will show notifications,  "denied" and "default" will not show
      // notifications. No reason to ask for notfications until the user is logged in
      Notifications.request();
    }

    // Changes to the active alerts list mean inactive have changed, we need to
    // fetch inactive again to properly check for/show deactivation notifications
    if (activeAlertsList.length < prevActiveAlerts.length) {
      fetchEnterpriseInactiveAlerts();
    }

    if (!User.role || userRole !== prevUserRole) {
      User.role = userRole;
    }

    Notifications.checkActiveNotifications(activeAlertsList);
    Notifications.checkInactiveNotifications(inactiveAlertsList);
  }

  checkForCookieData() {
    const { addUserData } = this.props;
    const rmdcCookie = cookies.get('rmdc') || {};

    if (rmdcCookie.dispatcherType) {
      User.role = rmdcCookie.dispatcherType;
    }
    if (rmdcCookie.enterpriseId && rmdcCookie.guid) {
      Logger.debug(JSON.stringify(rmdcCookie, null, 2));
      addUserData(rmdcCookie);
    } else {
      Logger.log('No cookie with valid data found for auto login');
    }
  }

  startEnterpriseAlertPolling() {
    this.stopEnterpriseAlertPolling();
    const { fetchEnterpriseActiveAlerts } = this.props;
    try {
      fetchEnterpriseActiveAlerts();
      this.enterpriseAlertTimer = setInterval(async () => {
        fetchEnterpriseActiveAlerts();
      }, this.enterpriseAlertTimerFrequency);
    } catch (error) {
      Logger.error('App >> Error with enterprise alert polling', error);
    }
  }

  stopEnterpriseAlertPolling() {
    window.clearInterval(this.enterpriseAlertTimer);
    this.enterpriseAlertTimer = null;
  }

  wrapInFocusView(ChildComponent, props = {}, opts = {}) {
    if (opts.killTimer) this.stopEnterpriseAlertPolling();
    return (
      <FocusView>
        <ChildComponent {...props} />
      </FocusView>
    );
  }

  renderPanelView(props) {
    const path = get(props, 'match.path', '');

    const {
      enterpriseConfig,
      fetchEnterpriseConfig,
    } = this.props;

    const route = ROUTES[path];
    route.path = path;

    if (!route) {
      Logger.error(`Route "${path}" not found, please double check for route typos`);
      return <Redirect to="/dashboard" />;
    }
    if (path !== '/dashboard' && !User.meetsPermission(route.requiredRole)) {
      Logger.error(`This route is blocked for user level "${User.role}", redirecting to dashboard`);
      return <Redirect to="/dashboard" />;
    }

    // Copy the props and hoist the params.id to the top level if the path requires an id
    const viewProps = Object.assign({}, props);
    viewProps.id = get(props, 'match.params.id', '');
    viewProps.uuid = get(props, 'match.params.uuid', '');

    this.startEnterpriseAlertPolling();
    // meta will alway be on the config object
    if (Object.keys(enterpriseConfig).length <= 1) {
      fetchEnterpriseConfig();
    }

    const ChildComponent = route.component;
    return (
      <PanelView pageTitle={route.title} routes={ROUTES} route={route}>
        <ChildComponent {...viewProps} />
      </PanelView>
    );
  }

  renderUnhandledRoute(props) {
    return this.wrapInFocusView(UnhandledRoute, props);
  }

  render() {
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={props => this.wrapInFocusView(UserAccess, props, {
            killTimer: true,
          })}
        />
        {Object.keys(ROUTES).map(routePath => (
          <Route
            key={`routeKey_${routePath}`}
            exact
            path={routePath}
            render={props => this.renderPanelView(props)}
          />
        ))}
        <Route
          exact
          path="/about"
          render={props => this.wrapInFocusView(Version, props, {
            killTimer: true,
          })}
        />
        <Route
          path="*"
          render={props => this.renderUnhandledRoute(props)}
        />
      </Switch>
    );
  }
}

App.propTypes = {
  activeAlertsList: propTypes.array,
  inactiveAlertsList: propTypes.array,
  userLoggedIn: propTypes.bool,
  userRole: propTypes.string,
  enterpriseConfig: propTypes.object,

  fetchEnterpriseActiveAlerts: propTypes.func.isRequired,
  fetchEnterpriseInactiveAlerts: propTypes.func.isRequired,
  fetchEnterpriseConfig: propTypes.func.isRequired,
  fetchBackendUrl: propTypes.func.isRequired,
  fetchVersion: propTypes.func.isRequired,
  signOutUser: propTypes.func.isRequired,
  addUserData: propTypes.func.isRequired,
};

App.defaultProps = {
  activeAlertsList: [],
  inactiveAlertsList: [],
  userLoggedIn: false,
  userRole: '',
  enterpriseConfig: {},
};
