import alarm from 'sounds/alarm.mp3';
import beep from 'sounds/beep.mp3';
import siren from 'sounds/siren.mp3';

class Sounds {
  constructor() {
    this.player = new Audio();
    this.player.loop = false;
    this.player.muted = true;
    this.player.volume = 0.25;

    this.ready = false;
    this.loop = false;
    this.timesPlayed = 0;

    this.stopEventDetected = false;

    this.player.onended = () => {
      if (this.loop && !this.stopEventDetected) {
        this.timesPlayed += 1;
        let currentVolume = this.player.volume;
        currentVolume += 0.25;
        if (currentVolume > 1) currentVolume = 1;
        this.player.volume = currentVolume;
        this.player.play();
      }
    };

    document.body.addEventListener('click', () => {
      this.stop();
    });
    document.body.addEventListener('touchstart', () => {
      this.stop();
    });
    document.body.addEventListener('mousemove', () => {
      this.stop();
    });
  }

  play(effect, opts) {
    if (!this.ready) return;
    const { repeat } = opts;

    this.stopEventDetected = false;
    this.timesPlayed = 0;
    this.loop = repeat || false;

    this.player.volume = 0.25;
    this.player.src = effect;
    this.player.play();
  }

  stop() {
    if (!this.ready) {
      this.player.muted = false;
      this.ready = true;
      return;
    }

    this.stopEventDetected = true;

    if (this.timesPlayed >= 1) {
      this.player.pause();
      this.player.src = null;
    }
  }

  alarm(opts = {}) {
    this.play(alarm, opts);
  }

  beep(opts = {}) {
    this.play(beep, opts);
  }

  siren(opts = {}) {
    this.play(siren, opts);
  }
}

export default new Sounds();
