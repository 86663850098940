import { INFORMATION } from 'store/user/const';
import { ACTIVE_ALERTS } from './const';

const initialState = {
  details: {},
  order: [],
  meta: {
    etag: '',
    requesting: false,
    errorMessage: '',
    failCount: 0,
    failed: false,
  },
};

function alertsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIVE_ALERTS.REQUEST: {
      return {
        ...state,
        meta: {
          ...state.meta,
          errorMessage: '',
          requesting: true,
        },
      };
    }

    case ACTIVE_ALERTS.RECEIVE: {
      return {
        ...state,
        ...action.response.data,
        meta: {
          ...initialState.meta,
          etag: action.response.etag,
        },
      };
    }

    case ACTIVE_ALERTS.REJECT: {
      const newFailCount = state.meta.failCount + 1;
      const newFailed = newFailCount >= 3;
      return {
        ...initialState,
        meta: {
          ...initialState.meta,
          errorMessage: action.error,
          failCount: newFailCount,
          failed: newFailed,
        },
      };
    }

    case ACTIVE_ALERTS.END: {
      return {
        ...state,
        meta: {
          ...state.meta,
          requesting: false,
        },
      };
    }

    case INFORMATION.CLEAR: {
      return initialState;
    }

    default:
      return state;
  }
}

export default alertsReducer;
