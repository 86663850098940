import { combineReducers } from 'redux';

import active from './active/reducer';
import inactive from './inactive/reducer';
import notes from './notes/reducer';

const deviceReducer = combineReducers({
  active,
  inactive,
  notes,
});

export default deviceReducer;
