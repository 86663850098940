import React, { Component } from 'react';
import propTypes from 'prop-types';

import { TableInfo } from 'rmwc-components';

import styles from './Version.module.css';

class Version extends Component {
  componentDidMount() {
    const { fetchVersion } = this.props;
    fetchVersion();
  }

  render() {
    const { version } = this.props;
    return (
      <div className={styles.wrapper}>
        <TableInfo data={version} />
      </div>
    );
  }
}

Version.propTypes = {
  version: propTypes.object,

  fetchVersion: propTypes.func.isRequired,
};

Version.defaultProps = {
  version: {},
};

export default Version;
