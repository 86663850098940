import { INFORMATION } from 'store/user/const';
import { BEACONS } from './const';

const initialState = {
  list: [],
  meta: {
    etag: '',
    requesting: false,
    errorMessage: false,
  },
};

function beaconsReducer(state = initialState, action) {
  switch (action.type) {
    case BEACONS.REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          errorMessage: '',
          requesting: true,
        },
      };

    case BEACONS.RECEIVE:
      return {
        ...state,
        list: action.response.data,
        meta: {
          ...initialState.meta,
          etag: action.response.etag,
        },
      };

    case BEACONS.REJECT:
      return {
        ...initialState,
        meta: {
          ...initialState.meta,
          errorMessage: action.error,
        },
      };

    case BEACONS.END:
      return {
        ...state,
        meta: {
          ...state.meta,
          requesting: false,
        },
      };

    case INFORMATION.CLEAR: {
      return initialState;
    }

    default:
      return state;
  }
}

export default beaconsReducer;
