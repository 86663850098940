import {
  configureActions,
  checkError,
  checkNoUpdate,
} from 'store/helpers';

import {
  getEnterpriseInactiveAlerts,
} from 'lib/api/alerts';
import {
  inactiveAlertsMetaSelector,
} from './selectors/inactiveAlertsSelector';

import {
  INACTIVE_ALERTS,
} from './const';

const inactiveAlertActions = configureActions(INACTIVE_ALERTS);
export const fetchEnterpriseInactiveAlerts = () => async (dispatch, getState) => {
  const {
    etag = '',
    requesting = false,
  } = inactiveAlertsMetaSelector(getState());

  if (requesting) return;
  dispatch(inactiveAlertActions.request());
  const response = await getEnterpriseInactiveAlerts({ etag });
  if (checkError(dispatch, response, inactiveAlertActions.reject)) return;
  if (checkNoUpdate(dispatch, etag, response, inactiveAlertActions.end)) return;
  dispatch(inactiveAlertActions.receive(response));
};
