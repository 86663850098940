import React from 'react';
import classnames from 'classnames';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Lock } from 'rmwc-components';

import styles from './MenuItem.module.css';

const ACTIVE_ALERTS_URL = 'alerts/active';

function MenuItem(props) {
  const {
    route,
    alerts,
    disabled,
  } = props;

  let itemTitle = route.title;
  let itemClassName = classnames(styles.item, route.active && styles.selected);

  if (route.path.match(ACTIVE_ALERTS_URL)) {
    itemTitle = `${itemTitle} (${alerts.length})`;
    if (alerts.length) {
      itemClassName = classnames(itemClassName, styles.activeAlerts);
    }
  }

  if (disabled) {
    return (
      <Lock reason="Requires admin level">
        <div className={classnames(itemClassName, styles.disabled)}>{itemTitle}</div>
      </Lock>
    );
  }

  return (
    <Link to={route.path} className={itemClassName}>
      {itemTitle}
    </Link>
  );
}

MenuItem.propTypes = {
  route: propTypes.object.isRequired,
  alerts: propTypes.array.isRequired,
  disabled: propTypes.bool,
};

export default MenuItem;
