import {
  configureActions,
  configureThunk,
  checkError,
} from 'store/helpers';

import {
  getAlertNotes,
  getEnterpriseNotes,
  postNote,
  putNote,
  deleteNote,
} from 'lib/api/alertnotes';

import {
  ALERT_NOTES,
  ENTERPRISE_NOTES,
  CREATE_NOTE,
  UPDATE_NOTE,
  DELETE_NOTE,
} from './const';

const fetchAlertNotesActions = configureActions(ALERT_NOTES);
export const fetchAlertNotes = alert_uuid => async (dispatch) => {
  dispatch(fetchAlertNotesActions.request());
  const response = await getAlertNotes(alert_uuid);
  if (checkError(dispatch, response, fetchAlertNotesActions.reject)) return 'fail';
  dispatch(fetchAlertNotesActions.receive({ alert_uuid, ...response }));
  return 'success';
};

export const fetchEnterpriseNotes = configureThunk(ENTERPRISE_NOTES, getEnterpriseNotes);
export const createAlertNote = configureThunk(CREATE_NOTE, postNote);
export const updateAlertNote = configureThunk(UPDATE_NOTE, putNote);
export const removeAlertNote = configureThunk(DELETE_NOTE, deleteNote);
