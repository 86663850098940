import { connect } from 'react-redux';

import {
  activeAlertsListSelector,
} from 'store/alerts/active/selectors/activeAlertsSelector';

import Dashboard from './components/Dashboard';

const mapStateToProps = state => ({
  activeAlertsList: activeAlertsListSelector(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
