import { INFORMATION } from 'store/user/const';
import { PHONES } from './const';

const initialState = {
  byId: {},
  all: [],
  meta: {
    errorMessage: '',
    requesting: false,
  },
};

function phonesReducer(state = initialState, action) {
  switch (action.type) {
    case PHONES.REQUEST: {
      return {
        ...state,
        meta: {
          ...state.meta,
          errorMessage: '',
          requesting: true,
        },
      };
    }

    case PHONES.RECEIVE: {
      const { data: phones } = action.response;
      return {
        ...state,
        byId: phones.byId,
        all: phones.all,
        meta: initialState.meta,
      };
    }

    case PHONES.REJECT: {
      return {
        ...state,
        byId: {},
        all: [],
        meta: {
          ...initialState.meta,
          errorMessage: action.error,
        },
      };
    }

    case INFORMATION.CLEAR: {
      return initialState;
    }

    default:
      return state;
  }
}

export default phonesReducer;
