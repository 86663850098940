import { INFORMATION } from 'store/user/const';
import {
  CONFIG,
  INTEGRATIONS,
  PROPERTIES,
} from './const';

const initialState = {
  integrations: {
    estimote: {
      estimoteEnabled: false,
      estimoteAppId: '',
      estimoteAppToken: '',
    },
    meta: {
      etag: '',
      errorMessage: '',
      requesting: false,
    },
  },
  properties: {
    data: {},
    meta: {
      etag: '',
      errorMessage: '',
      requesting: false,
    },
  },
  config: {
    meta: {
      etag: '',
      errorMessage: '',
      requesting: false,
    },
  },
};

function enterpriseReducer(state = initialState, action) {
  switch (action.type) {
    case CONFIG.REQUEST:
      return {
        ...state,
        config: {
          ...state.config,
          meta: {
            ...state.config.meta,
            requesting: true,
          },
        },
      };

    case CONFIG.RECEIVE: {
      const { data: config } = action.response;
      return {
        ...state,
        config: {
          ...config,
          meta: {
            ...state.config.meta,
            requesting: false,
          },
        },
      };
    }

    case CONFIG.REJECT:
      return {
        ...state,
        config: {
          ...state.config,
          meta: {
            ...state.config.meta,
            errorMessage: action.error,
            requesting: false,
          },
        },
      };

    case CONFIG.CLEAR:
      return {
        ...state,
        config: initialState.config,
      };

    case INTEGRATIONS.REQUEST:
      return {
        ...state,
        integrations: {
          ...state.integrations,
          meta: {
            ...state.integrations.meta,
            requesting: true,
          },
        },
      };

    case INTEGRATIONS.RECEIVE:
      return {
        ...state,
        integrations: {
          ...action.response.data.integrations,
          meta: {
            ...state.integrations.meta,
            requesting: false,
          },
        },
      };

    case INTEGRATIONS.REJECT:
      return {
        ...state,
        integrations: {
          ...initialState.integrations,
          meta: {
            ...state.integrations.meta,
            errorMessage: action.error,
            requesting: false,
          },
        },
      };

    case PROPERTIES.REQUEST:
      return {
        ...state,
        properties: {
          ...state.properties,
          meta: {
            ...state.properties.meta,
            requesting: true,
          },
        },
      };

    case PROPERTIES.RECEIVE:
      return {
        ...state,
        properties: {
          ...state.properties,
          data: action.response.data.properties,
          meta: {
            ...state.properties.meta,
            etag: action.response.etag,
            requesting: false,
          },
        },
      };

    case PROPERTIES.REJECT:
      return {
        ...state,
        properties: {
          ...initialState.properties,
          meta: {
            ...state.properties.meta,
            errorMessage: action.error,
            requesting: false,
          },
        },
      };

    case INFORMATION.CLEAR: {
      return initialState;
    }

    default:
      return state;
  }
}

export default enterpriseReducer;
