import { createSelector } from 'reselect';

const DEFAULT_STATE = {};

const generalSelector = state => state.general || DEFAULT_STATE;

const backendUrlSelector = createSelector(
  generalSelector,
  general => general.backendUrl || '',
);

const tablesSelector = createSelector(
  generalSelector,
  general => general.tables || {},
);

const versionSelector = createSelector(
  generalSelector,
  general => general.version || '',
);

const isProdEnvSelector = createSelector(
  versionSelector,
  version => version.environment === 'blue' || version.environment === 'green',
);

export default tablesSelector;

export {
  backendUrlSelector,
  isProdEnvSelector,
  tablesSelector,
  versionSelector,
};
