import { Logger } from 'rmwc-libraries';

const USER_ROLES = {
  admin: 0,
  operator: 1,
  viewer: 2,
};

class User {
  constructor() {
    this.props = {
      role: '',
    };
  }

  get role() {
    return this.props.role;
  }

  set role(newRole) {
    this.props.role = newRole;
    Logger.debug(`User >> role changed to: ${newRole}`);
  }

  meetsPermission(level = USER_ROLES.viewer) {
    // Initialing level to the lowest permission means this
    // will always succeed if no level was passed and the user has a role
    // If there is no role, the user has no permissions
    if (!this.role) return true;
    return USER_ROLES[this.role] <= level;
  }

  isAdmin() {
    return this.meetsPermission(USER_ROLES.admin);
  }

  isNotAdmin() {
    return !this.isAdmin();
  }

  isOperator() {
    return this.meetsPermission(USER_ROLES.operator);
  }

  isNotOperator() {
    return !this.isOperator();
  }

  isViewer() {
    return this.meetsPermission(USER_ROLES.viewer);
  }

  isNotViewer() {
    return !this.isViewer();
  }
}

const user = new User();
export default user;

export {
  USER_ROLES,
};
