import React, { Component } from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';

import User from 'lib/user';

import MenuItem from './MenuItem';
import styles from './Menu.module.css';

class Menu extends Component {
  constructor(props) {
    super(props);

    // Menu can only be toggled while in mobile when the
    // hamburger icon is available.
    this.state = {
      mobileOpen: false,
    };

    this.onHamburgerClick = this.onHamburgerClick.bind(this);
  }

  onHamburgerClick(e) {
    const { mobileOpen } = this.state;
    this.setState({
      mobileOpen: !mobileOpen,
    });
  }

  render() {
    const {
      currentRoute,
      routes,
      logoUrl,
      activeAlertsList,
      classFromParent,
      pageTitle,
    } = this.props;
    const {
      mobileOpen,
    } = this.state;

    const tabsClass = mobileOpen ? 'tabsOpen' : 'tabsClosed';

    return (
      <div className={classnames(classFromParent, styles.menu, styles[tabsClass])}>
        <div className={styles.hamburgerWrapper}>
          <div className={styles.hamburger} onClick={this.onHamburgerClick}>
            <div className={classnames(styles.bar, styles.bar1)} />
            <div className={classnames(styles.bar, styles.bar2)} />
            <div className={classnames(styles.bar, styles.bar3)} />
          </div>
          {pageTitle}
        </div>
        <div className={classnames(styles.tabs, styles[tabsClass])}>
          {Object.keys(routes).map((routePath) => {
            // No parametered routes on the menu
            if (routePath.match(/:/)) return null;

            const route = routes[routePath];
            route.path = routePath;
            const { meta: { highlight = {} } } = route;
            route.active = highlight[currentRoute.path] || currentRoute.path === routePath;

            return (
              <MenuItem
                route={route}
                disabled={!User.meetsPermission(route.requiredRole)}
                alerts={activeAlertsList}
                key={`key_path_${routePath}`}
              />
            );
          })}
          {Boolean(logoUrl) && (
            <div className={styles.licensee}>
              <p data-test-id="menu-licensee">
                Licensed by:
              </p>
              <img
                className={styles.licenseeImg}
                alt="Licensed by"
                src={logoUrl}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

Menu.propTypes = {
  pageTitle: propTypes.string,
  logoUrl: propTypes.string,
  activeAlertsList: propTypes.array,
  classFromParent: propTypes.string,

  currentRoute: propTypes.object.isRequired,
  routes: propTypes.object.isRequired,
};

Menu.defaultProps = {
  pageTitle: '',
  logoUrl: '',
  activeAlertsList: [],
  classFromParent: '',
};

export default (Menu);
