import {
  configureActions,
  checkError,
} from 'store/helpers';

import {
  getBackendUrl,
  getVersion,
} from 'lib/api/general';

import {
  TABLE_DATA,
  BACKEND_URL,
  VERSION,
} from './const';

const tableActions = configureActions(TABLE_DATA);
export const addTableData = (name, data) => (dispatch, getState) => {
  const tableData = Object.assign({}, data);
  delete tableData.searchData;
  const response = { name, data: tableData };
  dispatch(tableActions.receive(response));
};

export const removeTableData = () => (dispatch, getState) => {
  dispatch(tableActions.clear());
};

const backendUrlActions = configureActions(BACKEND_URL);
export const fetchBackendUrl = () => async (dispatch) => {
  dispatch(backendUrlActions.request());
  const response = await getBackendUrl();
  if (checkError(dispatch, response, backendUrlActions.reject)) return 'fail';
  dispatch(backendUrlActions.receive(response));
  return 'success';
};

const versionActions = configureActions(VERSION);
export const fetchVersion = () => async (dispatch) => {
  dispatch(versionActions.request());
  const response = await getVersion();
  if (checkError(dispatch, response, versionActions.reject)) return 'fail';
  dispatch(versionActions.receive(response));
  return 'success';
};
