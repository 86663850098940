import {
  INFORMATION,
} from './const';

const initialState = {
  loginError: '',
  requesting: false,
  userLoggedIn: false,
  info: {},
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case 'AUTH_EXPIRED': {
      return {
        ...state,
        userLoggedIn: false,
      };
    }

    case INFORMATION.REQUEST: {
      return {
        ...state,
        loginError: '',
        requesting: true,
      };
    }

    case INFORMATION.RECEIVE: {
      const { response: user } = action;
      return {
        ...initialState,
        userLoggedIn: true,
        info: user || {},
      };
    }

    case INFORMATION.REJECT: {
      return {
        ...state,
        loginError: action.error,
        requesting: false,
      };
    }

    case INFORMATION.CLEAR:
      return initialState;

    default:
      return state;
  }
}

export default userReducer;
