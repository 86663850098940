import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';

import { Button, TableInfo, AlertImportantInfo } from 'rmwc-components';
import { Locale } from 'rmwc-libraries';
import { alertHelpers, timeHelpers } from 'rmwc-helpers';
const { formatLatLong, formatCustomFields } = alertHelpers;
const { formatDate } = timeHelpers;

import User from 'lib/user';

import styles from './AlertDetails.module.css';

class AlertDetails extends Component {
  constructor(props) {
    super(props);

    this.setAsFalseAlert = this.setAsFalseAlert.bind(this);
  }

  setAsFalseAlert() {
    const {
      alert = {},
      setFalseAlert,
    } = this.props;
    setFalseAlert(alert.id);
  }

  renderAlertDetails(alert) {
    const alertInfo = formatCustomFields(alert, {
      'ID': alert.id,
      'Date/Time': formatDate(alert.createdAt),
      'Start Address': alert.originalAddress,
      'Latest Lat/Long': formatLatLong(alert),
    });
    return <TableInfo className={styles.info} data={alertInfo} />;
  }

  render() {
    const { alert = {} } = this.props;

    if (!alert) {
      return (
        <Fragment>
          <h3 className={styles.header}>Alert Details:</h3>
          <div>No active alert details to show</div>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <h3 className={styles.header}>Alert Details:</h3>
        <AlertImportantInfo alert={alert} />
        {this.renderAlertDetails(alert)}
        <Button
          text={Locale.t('set_as_false_button')}
          onClick={this.setAsFalseAlert}
          color="red"
          confirm={Locale.t('set_as_false_confirmation')}
          locked={User.isNotOperator()}
        />
      </Fragment>
    );
  }
}

AlertDetails.propTypes = {
  alert: propTypes.object,

  setFalseAlert: propTypes.func.isRequired,
};

AlertDetails.defaultProps = {
  alert: {},
};

export default AlertDetails;
