import React, { Component } from 'react';
import propTypes from 'prop-types';

import { alertHelpers, batteryHelpers } from 'rmwc-helpers';
const { formatRoomAndFloor } = alertHelpers;
const { getBatteryColor } = batteryHelpers;

import TableList from 'modules/components/TableList';

class Beacons extends Component {
  componentDidMount() {
    const { fetchBeacons } = this.props;
    fetchBeacons();
  }

  getTableData() {
    const {
      beacons = [],
      isProdEnv,
    } = this.props;

    const formattedBeacons = this.formatBeaconsData(beacons);

    const tableData = {
      title: 'Beacons List',
      cellDataList: formattedBeacons,
      cellDefinitions: [
        {
          header: {
            label: 'Name',
          },
          text: {
            key: 'name',
          },
        },
        {
          header: {
            label: 'Location',
          },
          text: {
            keys: ['roomNumber', 'floor'],
            formatter: (data) => {
              const [roomNumber, floor] = data;
              return formatRoomAndFloor(roomNumber, floor, 'Not Provided');
            },
          },
        },
        {
          header: {
            label: 'Battery',
          },
          text: {
            key: 'parsedBatteryLife',
            formatter: data => data.toUpperCase(),
            color: getBatteryColor,
          },
        },
        ...!isProdEnv ? [{
          header: {
            label: 'Battery MV',
          },
          text: {
            key: 'parsedBatteryLifeMv',
            formatter: (data) => {
              if (data) {
                return data;
              }
              return 'N/A';
            },
          },
        }] : [],
        ...!isProdEnv ? [{
          header: {
            label: 'Version',
          },
          text: {
            key: 'versionCode',
            formatter: (data) => {
              let parsedVersionCode;
              switch (data) {
                case 1:
                  parsedVersionCode = 'v0.0.3/v0.0.5';
                  break;
                case 6:
                  parsedVersionCode = 'v0.0.6';
                  break;
                default:
                  parsedVersionCode = 'N/A';
              }
              return parsedVersionCode;
            },
          },
        }] : [],
        {
          header: {
            label: 'Last Updated',
          },
          text: {
            key: 'lastUpdated',
          },
        },
      ],
    };
    return tableData;
  }

  formatBeaconsData(beacons) {
    // Remove "private" values from prod
    const { isProdEnv } = this.props;

    if (!isProdEnv) {
      return beacons;
    }

    const formattedBeacons = [];

    beacons.forEach((beacon) => {
      const {
        floor,
        lastUpdated,
        name,
        parsedBatteryLife,
        roomNumber,
        uuid,
        versionCode,
      } = beacon;

      formattedBeacons.push({
        floor,
        lastUpdated,
        name,
        parsedBatteryLife,
        roomNumber,
        uuid,
        versionCode,
      });
    });

    return formattedBeacons;
  }

  render() {
    const {
      requesting,
    } = this.props;

    return (
      <TableList
        requesting={requesting}
        data={this.getTableData()}
        download="Beacons"
      />
    );
  }
}

Beacons.propTypes = {
  beacons: propTypes.array,
  requesting: propTypes.bool,

  fetchBeacons: propTypes.func.isRequired,
  isProdEnv: propTypes.bool.isRequired,
};

Beacons.defaultProps = {
  beacons: [],
  requesting: false,
};

export default Beacons;
