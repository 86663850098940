import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';

import { Form, Button, TableInfo } from 'rmwc-components';
import { timeHelpers, validationHelpers } from 'rmwc-helpers';
const { formatDate } = timeHelpers;
const { isValidEmail } = validationHelpers;

import User from 'lib/user';

import styles from './DispatcherDetails.module.css';

class DispatcherDetails extends Component {
  constructor(props) {
    super(props);

    this.updateDispatcher = this.updateDispatcher.bind(this);
    this.validateDispatcher = this.validateDispatcher.bind(this);
    this.unlockAccount = this.unlockAccount.bind(this);
    this.disableDispatcher = this.disableDispatcher.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  componentDidMount() {
    const { fetchDispatchers } = this.props;
    const dispatcher = this.getDispatcher();
    if (!dispatcher) fetchDispatchers();
  }

  getDispatcher() {
    const { id, dispatchers } = this.props;
    if (!dispatchers || !dispatchers.byId) return null;
    const dispatcher = dispatchers.byId[id];
    if (!dispatcher) return null;
    return dispatcher;
  }

  getDispatcherFormData(dispatcher) {
    return {
      name: {
        value: dispatcher.name,
        label: 'Name',
        size: 'half_width',
      },
      dispatcher_type: {
        value: dispatcher.dispatcherType,
        label: 'Role',
        type: 'dropdown',
        size: 'half_width',
        options: [{
          value: 'viewer',
          label: 'Viewer',
        }, {
          value: 'operator',
          label: 'Operator',
        }, {
          value: 'admin',
          label: 'Admin',
        }],
      },
      email: {
        value: dispatcher.email,
        label: 'Email',
        size: 'half_width',
        disabled: true,
      },
      nickname: {
        value: dispatcher.nickname,
        label: 'Nickname',
        size: 'half_width',
      },
    };
  }

  updateDispatcher(data = {}) {
    const { id, updateDispatcher } = this.props;
    return updateDispatcher({
      ...data,
      guid: id,
    });
  }

  validateDispatcher(data) {
    const {
      name,
      email,
    } = data;
    const errors = {};

    if (!name.trim()) {
      errors.name = 'Name must not be empty';
    }

    if (email && !isValidEmail(email)) {
      errors.email = 'Please provide a valid email';
    }

    return errors;
  }

  unlockAccount(event) {
    const { backendUrl } = this.props;
    window.open(`${backendUrl}/auth/unlock/new`);
  }

  disableDispatcher(event) {
    const {
      id,
      disableDispatcher,
      fetchDispatchers,
    } = this.props;
    disableDispatcher(id).then(() => {
      fetchDispatchers();
    });
  }

  resetPassword(event) {
    const { backendUrl } = this.props;
    window.open(`${backendUrl}/auth/password/new`);
  }

  render() {
    const dispatcher = this.getDispatcher();
    if (!dispatcher) {
      return <div>Dispatcher details not available at the moment</div>;
    }

    const dispatcherInfo = {
      'Last Sign In At': formatDate(dispatcher.lastSignInAt) || 'Not provided',
      'Account Creation Date': formatDate(dispatcher.createdAt),
      'Account Status': dispatcher.isDisabled ? 'DISABLED' : 'ENABLED',
      'Current Sign-In IP': dispatcher.currentSignInIp || 'Not provided',
      'Last Sign-In IP': dispatcher.lastSignInIp || 'Not provided',
      'Account Lock Status': dispatcher.isLocked ? 'LOCKED' : 'UNLOCKED',
    };

    return (
      <Fragment>
        <Form
          name="Update Dispatcher"
          fieldInfo={this.getDispatcherFormData(dispatcher)}
          validate={this.validateDispatcher}
          onSubmit={this.updateDispatcher}
          submitLabel="Save"
          disabled={User.isNotAdmin()}
        />
        <TableInfo data={dispatcherInfo} />
        <div className={styles.buttonWrapper}>
          <Button
            title={!dispatcher.isLocked ? 'Dispatcher is not locked' : ''}
            text="Unlock Account"
            color="green"
            onClick={this.unlockAccount}
            disabled={!dispatcher.isLocked}
            locked={User.isNotAdmin()}
          />
          <Button
            text="Disable Dispatcher"
            color="red"
            onClick={this.disableDispatcher}
            disabled={dispatcher.isDisabled}
            confirm={`Are you sure you want to disable ${dispatcher.name}?`}
            locked={User.isNotAdmin()}
          />
          <Button
            text="Reset Password"
            onClick={this.resetPassword}
          />
        </div>
      </Fragment>
    );
  }
}

DispatcherDetails.propTypes = {
  id: propTypes.string,
  dispatchers: propTypes.object,

  backendUrl: propTypes.string.isRequired,
  disableDispatcher: propTypes.func.isRequired,
  fetchDispatchers: propTypes.func.isRequired,
  updateDispatcher: propTypes.func.isRequired,
};

DispatcherDetails.defaultProps = {
  id: '',
  dispatchers: {},
};

export default DispatcherDetails;
