import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Logo } from 'rmwc-components';
import { Symbols, Locale } from 'rmwc-libraries';

import Store from 'store/store';

import cookies from 'lib/cookies';

import { setTitleBar } from 'helpers/titleBar';

import Menu from 'modules/components/Menu';
import styles from './PanelView.module.css';

class PanelView extends Component {
  constructor(props) {
    super(props);

    this.signOutUser = this.signOutUser.bind(this);
  }

  componentDidMount() {
    this.checkUserLoggedIn();
  }

  componentDidUpdate() {
    this.checkUserLoggedIn();
  }

  checkUserLoggedIn() {
    const { userLoggedIn } = this.props;
    const rmdcCookie = cookies.get('rmdc');
    if (!userLoggedIn && !rmdcCookie) this.signOutUser();
  }

  signOutUser() {
    const { signOutUser } = this.props;
    signOutUser();
    Store.history.push('/');
  }

  render() {
    const {
      route,
      routes,
      activeAlertsList,
      children,
      pageTitle,
      haveAlertsFailed,
    } = this.props;

    setTitleBar(activeAlertsList);

    return (
      <Fragment>
        {haveAlertsFailed && (
          <div className={styles.connectionFailed}>
            {Symbols.alert} {Locale.t('error_connectivity')}
          </div>
        )}
        <div className={styles.pageWrapper}>
          <div className={styles.header}>
            <Logo
              className={styles.logo}
              linkhome
              onclick={() => { Store.history.push('/dashboard'); }}
              leftText="dispatch"
              rightText="center"
            />
            <Link to="/" onClick={this.signOutUser} className={styles.signOut}>{Locale.t('sign_out')}</Link>
          </div>
          <Menu
            classFromParent={styles.menu}
            pageTitle={pageTitle}
            routes={routes}
            currentRoute={route}
          />
          <div className={styles.details}>
            {children}
          </div>
        </div>
      </Fragment>
    );
  }
}

PanelView.propTypes = {
  pageTitle: propTypes.string,
  activeAlertsList: propTypes.array,
  haveAlertsFailed: propTypes.bool,
  userLoggedIn: propTypes.bool,

  route: propTypes.object.isRequired,
  routes: propTypes.object.isRequired,
  signOutUser: propTypes.func.isRequired,

  children: propTypes.element.isRequired,
};

PanelView.defaultProps = {
  pageTitle: '',
  activeAlertsList: [],
  haveAlertsFailed: false,
  userLoggedIn: false,
};

export default PanelView;
