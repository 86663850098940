import { connect } from 'react-redux';

import { versionSelector } from 'store/general/selectors/generalSelector';

import { fetchVersion } from 'store/general/thunks';

import Version from './components/Version';

const mapStateToProps = state => ({
  version: versionSelector(state),
});

const mapDispatchToProps = {
  fetchVersion,
};

export default connect(mapStateToProps, mapDispatchToProps)(Version);
