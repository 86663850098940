import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';

import { Form } from 'rmwc-components';
import User from 'lib/user';

/*
  NOTE: 12/13/2019 Emergency Contacts
    Emergency Contacts are working somewhat at the moment. I was in the process
    of testing them and was having trouble getting the services to accept the payload.
    422 Unprocessable Entity will also wipe all contacts, which is a pain to deal with.
    Abandoning it at this point and commenting out the forms as these fields may not be used

  NOTE: 12/13/2019 External Device IDs
    External Device IDs are used for knowcross integration
    Currently they are not modified after initial setup and the functionality
    to add/change them does not work in the electron legacy app.
    Positive server response indicating a successful save does not show the
    external device IDs appearing in any of the users calls:
      users
      user?type=full_summary
      users?enterprise_guid=ENTERPRISE_GUID&user_guid=USER_GUID
    Creating a new "user" in the app and adding an integration there
    also does show the device IDs in any call
*/

class PhoneDetails extends Component {
  constructor(props) {
    super(props);

    this.submitProfileFormData = this.submitProfileFormData.bind(this);
    this.validateProfileFormData = this.validateProfileFormData.bind(this);
    this.submitExternalDeviceIdData = this.submitExternalDeviceIdData.bind(this);
  }

  componentDidMount() {
    const { phones, fetchPhones } = this.props;
    // If a user refreshes on a user detail page, we'll need
    // to fetch all users again
    if (!phones.byId || !Object.keys(phones.byId).length) {
      fetchPhones();
    }
  }

  getProfileFormData(currentPhone) {
    return {
      firstName: {
        value: currentPhone.firstName || '',
        label: 'First Name (Device)',
        size: 'half_width',
      },
      lastName: {
        value: currentPhone.lastName || '',
        label: 'Last Name (Location)',
        size: 'half_width',
      },
    };
  }

  getExternalDeviceIdFormData(currentPhone) {
    const { externalDeviceIds = [] } = currentPhone;
    return {
      externalDeviceIdOne: {
        value: externalDeviceIds[0] || '',
        label: 'Device #1',
      },
      externalDeviceIdTwo: {
        value: externalDeviceIds[1] || '',
        label: 'Device #2',
      },
      externalDeviceIdThree: {
        value: externalDeviceIds[2] || '',
        label: 'Device #3',
      },
    };
  }

  submitProfileFormData(data) {
    const { id, updatePhone } = this.props;
    return updatePhone(id, data);
  }

  submitExternalDeviceIdData(data) {
    const { id, updatePhoneExternalDeviceIds } = this.props;
    return updatePhoneExternalDeviceIds(id, data);
  }

  validateProfileFormData(data) {
    const {
      firstName,
      lastName,
    } = data;
    const errors = {};

    if (!firstName.trim()) {
      errors.firstName = 'Device name must not be empty';
    }
    if (!lastName.trim()) {
      errors.lastName = 'Device location must not be empty';
    }

    return errors;
  }

  render() {
    const { phones, id } = this.props;
    if (!phones.byId) return null;
    const currentPhone = phones.byId[id];

    // TODO: Better error message for when a user isn't found
    if (!currentPhone) return <div />;

    return (
      <Fragment>
        <Form
          name="Profile"
          fieldInfo={this.getProfileFormData(currentPhone)}
          validate={this.validateProfileFormData}
          onSubmit={this.submitProfileFormData}
          disabled={User.isNotAdmin()}
        />
        <Form
          name="External Device IDs"
          fieldInfo={this.getExternalDeviceIdFormData(currentPhone)}
          onSubmit={this.submitExternalDeviceIdData}
          disabled={User.isNotAdmin()}
        />
      </Fragment>
    );
  }
}

PhoneDetails.propTypes = {
  id: propTypes.string,
  phones: propTypes.object,

  fetchPhones: propTypes.func.isRequired,
  updatePhone: propTypes.func.isRequired,
  updatePhoneExternalDeviceIds: propTypes.func.isRequired,
};

PhoneDetails.defaultProps = {
  id: '',
  phones: {},
};

export default PhoneDetails;
