import { INFORMATION } from 'store/user/const';
import { CELLULAR } from './const';

const initialState = {
  list: [],
  meta: {
    etag: '',
    requesting: false,
    errorMessage: '',
  },
};

function cellularReducer(state = initialState, action) {
  switch (action.type) {
    case CELLULAR.REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          errorMessage: '',
          requesting: true,
        },
      };

    case CELLULAR.RECEIVE:
      return {
        ...state,
        list: (action.response.data || []).map((device) => {
          return {
            ...device,
            deviceType: 'Cellular',
          };
        }),
        meta: {
          ...initialState.meta,
          etag: action.response.etag,
        },
      };

    case CELLULAR.REJECT:
      return {
        ...initialState,
        meta: {
          ...initialState.meta,
          errorMessage: action.error,
        },
      };

    case CELLULAR.END:
      return {
        ...state,
        meta: {
          ...state.meta,
          requesting: false,
        },
      };

    case INFORMATION.CLEAR: {
      return initialState;
    }

    default:
      return state;
  }
}

export default cellularReducer;
