import { createSelector } from 'reselect';
import enterpriseSelector from './enterpriseSelector';

const DEFAULT_STATE = {};

const propertiesSelector = createSelector(
  enterpriseSelector,
  enterprise => enterprise.properties.data || DEFAULT_STATE,
);

export default propertiesSelector;
