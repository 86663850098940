import { Locale } from 'rmwc-libraries';

import {
  requestOptions,
  makeRequest,
} from './helper';

const requestValidator = (status, response = {}) => {
  let errorMessage = null;
  if (status !== 200) {
    errorMessage = Locale.t('error_service_unauthorized');
  }
  if (status === 401) {
    errorMessage = Locale.t('error_service_invalid_login');
  }
  if (status === 403) {
    errorMessage = Locale.t('error_service_unauthorized');
  }
  if (response.error) {
    const { message } = response;
    if (typeof message === 'string') {
      errorMessage = message;
    }
    errorMessage = message.error || (message.errors || [])[0] || Locale.t('error_service_generic');

    // TODO: BANDAID!!!
    if (errorMessage && errorMessage.indexOf('before your account can be activated') !== -1) {
      errorMessage = Locale.t('error_account_disabled');
    }
    // END BANDAID!!!
  }
  return errorMessage;
};

async function getUser({ email, password }) {
  const options = requestOptions('POST', {
    body: {
      email,
      password,
    },
  });
  return makeRequest('login', options, {
    path: 'api',
    requestValidator,
  });
}

async function signOut() {
  return makeRequest('logout', requestOptions(), {
    path: 'api',
    requestValidator,
  });
}

export {
  getUser,
  signOut,
};
