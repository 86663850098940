import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import Store from 'store/store';

import User from 'lib/user';

import { timeHelpers } from 'rmwc-helpers';
const { formatDate } = timeHelpers;

import { Form, Button } from 'rmwc-components';

import styles from './Notes.module.css';

class Notes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editUUID: null,
    };

    this.onCreate = this.onCreate.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.validateNote = this.validateNote.bind(this);
    this.onNoteSuccess = this.onNoteSuccess.bind(this);
  }

  componentDidMount() {
    const { alert, fetchAlertNotes } = this.props;
    if (alert.uuid) fetchAlertNotes(alert.uuid);
  }

  componentDidUpdate(prevProps) {
    const { alert: prevAlert } = prevProps;
    const { alert: nextAlert, fetchAlertNotes } = this.props;
    if (!prevAlert && nextAlert) fetchAlertNotes(nextAlert.uuid);
  }

  onCreate(data) {
    const {
      userInfo,
      alert,
      createAlertNote,
    } = this.props;
    return createAlertNote({
      note: data.text,
      alert_uuid: alert.uuid,
      user_uuid: userInfo.guid,
      enterprise_uuid: Store.enterpriseId,
    });
  }

  onNoteSuccess() {
    const { alert, fetchAlertNotes } = this.props;
    this.setState({ editUUID: null });
    fetchAlertNotes(alert.uuid);
  }

  onUpdate(data) {
    const { editUUID } = this.state;
    const { updateAlertNote } = this.props;
    return updateAlertNote(editUUID, {
      note: data.text,
    });
  }

  onEdit(uuid) {
    const { editUUID } = this.state;
    const newUUID = (editUUID === uuid) ? null : uuid;
    this.setState({
      editUUID: newUUID,
    });
  }

  async onDelete(uuid) {
    const { alert, removeAlertNote, fetchAlertNotes } = this.props;
    const status = await removeAlertNote(uuid);
    if (status === 'success') fetchAlertNotes(alert.uuid);
  }

  validateNote(data) {
    const { text } = data;
    const errors = {};

    if (!text.trim()) {
      errors.text = 'Note must not be empty';
    }

    return errors;
  }

  render() {
    const { editUUID } = this.state;
    const { alert, allNotes } = this.props;
    if (!alert) return null;

    const notes = allNotes.byAlertUUID[alert.uuid] || [];
    const notesClass = classnames(styles.notes, {
      [styles.notesEmpty]: !notes.length,
    });

    return (
      <div className={styles.notesSection}>
        <div className={notesClass}>
          {notes.map((note) => {
            const inEdit = editUUID === note.noteUuid;
            const noteClass = classnames(styles.note, {
              [styles.userNote]: note.isAuthor,
            });
            return (
              <div key={`note_${note.noteUuid}`} className={noteClass}>
                <div className={styles.noteHeader}>
                  <span className={styles.noteAuthor}>{note.userName}</span>
                  {note.isAuthor && (
                    <Fragment>
                      <Button
                        text={String.fromCharCode(215)}
                        onClick={() => { this.onDelete(note.noteUuid); }}
                        confirm="Delete this note?"
                        classNameOnly={classnames(styles.noteDelete, 'noselect')}
                      />
                      <Button
                        text={String.fromCharCode(9998)}
                        onClick={() => { this.onEdit(note.noteUuid); }}
                        classNameOnly={classnames(styles.noteEdit, 'noselect')}
                      />
                    </Fragment>
                  )}
                </div>
                <div className={styles.noteMeta}>
                  <div className={styles.noteDate}>Created: {formatDate(note.createdAt)}</div>
                  {note.createdAt !== note.updatedAt && (
                    <div className={styles.noteDate}>Edited: {formatDate(note.updatedAt)}</div>
                  )}
                </div>
                <div className={styles.noteText}>
                  {inEdit && (
                    <Form
                      fieldInfo={{
                        text: {
                          label: 'Edit Note:',
                          type: 'textarea',
                          value: note.text || note.note,
                        },
                      }}
                      validate={this.validateNote}
                      onSubmit={this.onUpdate}
                      onSuccess={this.onNoteSuccess}
                      submitLabel="Save"
                      cancel={{
                        label: 'Cancel',
                        handler: () => {
                          this.onEdit(note.noteUuid);
                        },
                      }}
                    />
                  )}
                  {!inEdit && (note.text || note.note || '')}
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.addNote}>
          <Form
            fieldInfo={{
              text: {
                label: 'Create New Note:',
                type: 'textarea',
              },
            }}
            validate={this.validateNote}
            onSubmit={this.onCreate}
            onSuccess={this.onNoteSuccess}
            resetOnSuccess={true}
            submitLabel="Save"
            disabled={Boolean(editUUID) || User.isNotOperator()}
            disabledMessage=""
          />
        </div>
      </div>
    );
  }
}

Notes.propTypes = {
  userInfo: propTypes.object,
  allNotes: propTypes.object,
  alert: propTypes.object,

  fetchAlertNotes: propTypes.func.isRequired,
  createAlertNote: propTypes.func.isRequired,
  updateAlertNote: propTypes.func.isRequired,
  removeAlertNote: propTypes.func.isRequired,
};

Notes.defaultProps = {
  userInfo: {},
  allNotes: {},
  alert: {},
};

export default Notes;
