/* eslint prefer-rest-params: 0 */

export const isErrorResponse = (response) => {
  if (response.error || response instanceof Error || response.name === 'Error' || response.code === 'ENOTFOUND') {
    return true;
  }
  return false;
};

export const checkError = (dispatch, response, cb, opts = {}) => {
  const { ignoreAuth401 = false } = opts;
  if (isErrorResponse(response)) {
    const { message } = response;
    if (response.status === 401 && !ignoreAuth401) {
      dispatch({ type: 'AUTH_EXPIRED' });
      return true;
    }
    dispatch(cb(message));
    return true;
  }
  return false;
};

export const checkNoUpdate = (dispatch, etag, response, cb) => {
  if ((response.status === 304) || (etag && response.etag && etag === response.etag)) {
    dispatch(cb());
    return true;
  }
  return false;
};

export const configureConsts = (base, type) => {
  const obj = {};
  ['REQUEST', 'RECEIVE', 'REJECT', 'END', 'CLEAR'].forEach((action) => {
    obj[action] = `${base}/${action}_${type}`;
  });
  return obj;
};

export const configureActions = (CONSTS) => {
  return {
    request: () => {
      return {
        type: CONSTS.REQUEST,
      };
    },

    receive: (response) => {
      return {
        type: CONSTS.RECEIVE,
        response,
      };
    },

    reject: (error) => {
      return {
        type: CONSTS.REJECT,
        error,
      };
    },

    end: () => {
      return {
        type: CONSTS.END,
      };
    },

    clear: () => {
      return {
        type: CONSTS.CLEAR,
      };
    },
  };
};

export const configureThunk = (consts, handler) => {
  const actions = configureActions(consts);
  return function () {
    return async (dispatch) => {
      dispatch(actions.request());
      const response = await handler.apply(this, Array.prototype.slice.call(arguments));
      if (checkError(dispatch, response, actions.reject)) return 'fail';
      dispatch(actions.receive(response));
      return 'success';
    };
  };
};
