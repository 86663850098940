import cookies from 'lib/cookies';

import { createSelector } from 'reselect';
import userInfoSelector from './userInfoSelector';

const DEFAULT_STATE = '';

const userRoleSelector = createSelector(
  userInfoSelector,
  (info) => {
    if (!info.dispatcher_type) {
      try {
        const rmdcCookie = cookies.get('rmdc');
        return rmdcCookie.dispatcherType;
      } catch (e) {
        return DEFAULT_STATE;
      }
    }
    return info.dispatcherType || DEFAULT_STATE;
  },
);

export default userRoleSelector;
