import { createSelector } from 'reselect';
import userInfoSelector from './userInfoSelector';

const DEFAULT_STATE = '';

const userEnterpriseIdSelector = createSelector(
  userInfoSelector,
  info => info.enterpriseId || DEFAULT_STATE,
);

export default userEnterpriseIdSelector;
