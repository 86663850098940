import { Locale } from 'rmwc-libraries';

import {
  requestOptions,
  makeRequest,
} from './helper';

const requestValidator = (status) => {
  if (status === 401) {
    return Locale.t('error_service_generic');
  }
  return null;
};

async function getEnterpriseConfig() {
  return makeRequest('config', requestOptions());
}

async function getEnterpriseIntegrations() {
  return makeRequest('integrations', requestOptions(), { requestValidator });
}

async function getEnterpriseLTEDevices(opts = {}) {
  const { appId, appToken } = opts;
  const options = requestOptions('GET', {
    headers: {
      authorization: `Basic ${btoa(`${appId}:${appToken}`)}`,
    },
  });
  return makeRequest('ltedevices', options);
}

async function getEnterpriseBeacons() {
  const options = requestOptions('GET');
  return makeRequest('beacons', options);
}

async function getEnterpriseCellular() {
  const options = requestOptions('GET');
  return makeRequest('standalone', options);
}

async function getEnterpriseProperties() {
  return makeRequest('properties', requestOptions(), {
    dataParser: (data = {}) => {
      // TODO: BANAID
      // Clear out empty string arrays
      const dataCopy = Object.assign({}, data);
      let emails = dataCopy.properties.alertForwardingEmails;
      let sms = dataCopy.properties.alertForwardingSms;
      if (emails.length === 1 && !emails[0]) emails = [];
      if (sms.length === 1 && !sms[0]) sms = [];
      dataCopy.properties.alertForwardingEmails = emails;
      dataCopy.properties.alertForwardingSms = sms;
      return dataCopy;
      // END BANDAID
    },
  });
}

async function putEnterpriseProperties(properties) {
  const formattedProperties = {
    admin_email: properties.adminEmail,
    alert_forwarding: properties.alertForwardingEnabled,
    alert_forwarding_emails: properties.emailAddresses,
    alert_forwarding_sms: properties.phoneNumbers,
  };

  // TODO: BANDAID
  // Empty arrays are not accepted, there needs to be at least
  // an empty string for the service to accept the payload.
  // Without it, the service will update everything else and quietly
  // ignore the empty arrays.
  if (!formattedProperties.alert_forwarding_emails.length) {
    formattedProperties.alert_forwarding_emails.push('');
  }
  if (!formattedProperties.alert_forwarding_sms.length) {
    formattedProperties.alert_forwarding_sms.push('');
  }
  // END BANDAID

  const options = requestOptions('PUT', {
    body: formattedProperties,
  });
  const API = 'properties';
  return makeRequest(API, options);
}

export {
  getEnterpriseConfig,
  getEnterpriseIntegrations,
  getEnterpriseLTEDevices,
  getEnterpriseBeacons,
  getEnterpriseCellular,
  getEnterpriseProperties,
  putEnterpriseProperties,
};
