import { createSelector } from 'reselect';
import alertsSelector from 'store/alerts/selectors/alertsSelector';

const alertNotesSelector = createSelector(
  alertsSelector,
  alerts => alerts.notes || {},
);

export {
  alertNotesSelector,
};
