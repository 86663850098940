import React, { Component } from 'react';
import propTypes from 'prop-types';

import TableList from 'modules/components/TableList';
import { SignalStrength } from 'rmwc-components';
import { batteryHelpers, timeHelpers } from 'rmwc-helpers';
const { getBatteryColor } = batteryHelpers;
const { formatDeviceUptime } = timeHelpers;

class LTEDevices extends Component {
  constructor(props) {
    super(props);

    this.integrationsRequested = false;
  }

  componentDidMount() {
    const {
      integrationsMeta,
      fetchEnterpriseIntegrations,
      fetchCellular,
    } = this.props;

    if (!integrationsMeta.requesting) {
      fetchEnterpriseIntegrations();
    }
    fetchCellular();
  }

  componentDidUpdate(prevProps) {
    const { integrationsMeta: prevIntegrationsMeta } = prevProps;
    const { integrations, integrationsMeta, fetchEnterpriseLTEDevices } = this.props;
    // Integrations has finished being requested, we only ever want this to happen once and
    // fetch lteDevices only once for this page (without user interaction)
    if (!this.integrationsRequested && prevIntegrationsMeta.requesting && !integrationsMeta.requesting) {
      this.integrationsRequested = true;
      const estimote = integrations.estimote || {};
      const { estimoteEnabled, estimoteAppId, estimoteAppToken } = estimote;
      if (estimoteEnabled && estimoteAppId && estimoteAppToken) {
        fetchEnterpriseLTEDevices({
          appId: estimoteAppId,
          appToken: estimoteAppToken,
        });
      }
    }
  }

  getTableData() {
    const {
      lteDevices = [],
      cellularDevices = [],
      isProdEnv,
    } = this.props;

    const tableData = {
      title: 'LTE Devices List',
      cellDataList: cellularDevices.concat(lteDevices),
      cellDefinitions: [
        {
          header: {
            label: 'Device Name',
          },
          text: {
            key: 'name',
          },
        },
        {
          header: {
            label: 'IMEI',
          },
          text: {
            key: 'uuid',
          },
        },
        ...!isProdEnv ? [{
          header: {
            label: 'Carrier',
          },
          text: {
            key: 'carrier',
          },
        }] : [],
        {
          header: {
            label: 'Battery',
          },
          text: {
            key: 'parsedBatteryLife',
            formatter: data => data.toUpperCase(),
            color: getBatteryColor,
          },
        },
        ...!isProdEnv ? [{
          header: {
            label: 'RSSI',
          },
          text: {
            key: 'signalStrength',
          },
        }] : [],
        {
          header: {
            label: 'Signal Strength',
          },
          component: {
            key: 'signalStrength',
            render: (data) => {
              return <SignalStrength strength={data} />;
            },
          },
        },
        {
          header: {
            label: 'Last Synced',
          },
          text: {
            key: 'updatedAt',
          },
        },
        {
          header: {
            label: 'Version',
          },
          text: {
            key: 'firmwareVersion',
          },
        },
      ],
    };
    return tableData;
  }

  render() {
    const {
      lteRequesting,
      cellularRequesting,
    } = this.props;

    return (
      <TableList
        requesting={lteRequesting || cellularRequesting}
        data={this.getTableData()}
        download="LTEDevices"
      />
    );
  }
}

LTEDevices.propTypes = {
  integrations: propTypes.object,
  integrationsMeta: propTypes.object,
  lteDevices: propTypes.array,
  lteRequesting: propTypes.bool,
  cellularDevices: propTypes.array,
  cellularRequesting: propTypes.bool,
  isProdEnv: propTypes.bool,

  fetchEnterpriseIntegrations: propTypes.func.isRequired,
  fetchEnterpriseLTEDevices: propTypes.func.isRequired,
  fetchCellular: propTypes.func.isRequired,
};

LTEDevices.defaultProps = {
  integrations: {},
  integrationsMeta: {},
  lteDevices: [],
  lteRequesting: false,
  cellularDevices: [],
  cellularRequesting: false,
  isProdEnv: true,
};

export default LTEDevices;
