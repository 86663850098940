import { createSelector } from 'reselect';
import alertsSelector from 'store/alerts/selectors/alertsSelector';

const activeAlertsSelector = createSelector(
  alertsSelector,
  alerts => alerts.active || {},
);

const activeAlertsDetailsSelector = createSelector(
  activeAlertsSelector,
  activeAlerts => activeAlerts.details || {},
);

const activeAlertsOrderSelector = createSelector(
  activeAlertsSelector,
  activeAlerts => activeAlerts.order || [],
);

const activeAlertsListSelector = createSelector(
  activeAlertsDetailsSelector,
  activeAlertsOrderSelector,
  (details, order) => order.map(id => details[id]),
);

const activeAlertsMetaSelector = createSelector(
  activeAlertsSelector,
  activeAlerts => activeAlerts.meta || {},
);

const activeAlertsRequestingSelector = createSelector(
  activeAlertsMetaSelector,
  meta => meta.requesting || false,
);

const activeAlertsFailedSelector = createSelector(
  activeAlertsMetaSelector,
  meta => meta.failed || false,
);

export {
  activeAlertsDetailsSelector,
  activeAlertsListSelector,
  activeAlertsMetaSelector,
  activeAlertsRequestingSelector,
  activeAlertsFailedSelector,
};
