import { connect } from 'react-redux';

import {
  zoomSelector,
  latitudeSelector,
  longitudeSelector,
} from 'store/enterprise/selectors/enterpriseConfigSelector';

// Note: API key comes from this library
import { AlertMap } from 'rmwc-components';

const mapStateToProps = state => ({
  zoom: zoomSelector(state),
  latitude: latitudeSelector(state),
  longitude: longitudeSelector(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AlertMap);
