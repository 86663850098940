import {
  configureActions,
  checkError,
} from 'store/helpers';

import {
  getEnterpriseConfig,
  getEnterpriseIntegrations,
  getEnterpriseProperties,
  putEnterpriseProperties,
} from 'lib/api/enterprise';

import {
  CONFIG,
  INTEGRATIONS,
  PROPERTIES,
  UPDATE_PROPERTIES,
} from './const';

const configActions = configureActions(CONFIG);
export const fetchEnterpriseConfig = () => async (dispatch) => {
  dispatch(configActions.request());
  const response = await getEnterpriseConfig();
  if (checkError(dispatch, response, configActions.reject)) return;
  dispatch(configActions.receive(response));
};

const integrationsActions = configureActions(INTEGRATIONS);
export const fetchEnterpriseIntegrations = () => async (dispatch) => {
  dispatch(integrationsActions.request());
  const response = await getEnterpriseIntegrations();
  if (checkError(dispatch, response, integrationsActions.reject)) return;
  dispatch(integrationsActions.receive(response));
};

const propertiesActions = configureActions(PROPERTIES);
export const fetchEnterpriseProperties = () => async (dispatch) => {
  dispatch(propertiesActions.request());
  const response = await getEnterpriseProperties();
  if (checkError(dispatch, response, propertiesActions.reject)) return;
  dispatch(propertiesActions.receive(response));
};

const updatePropertiesActions = configureActions(UPDATE_PROPERTIES);
export const updateEnterpriseProperties = properties => async (dispatch) => {
  dispatch(updatePropertiesActions.request());
  const response = await putEnterpriseProperties(properties);
  if (checkError(dispatch, response, updatePropertiesActions.reject)) return 'fail';
  dispatch(updatePropertiesActions.receive(response));
  dispatch(fetchEnterpriseProperties());
  return 'success';
};
