import { connect } from 'react-redux';

import { dispatchersListSelector } from 'store/dispatchers/selectors/dispatchersSelector';
import { backendUrlSelector } from 'store/general/selectors/generalSelector';

import {
  fetchDispatchers,
  updateDispatcher,
  disableDispatcher,
} from 'store/dispatchers/thunks';

import DispatcherDetails from './components/DispatcherDetails';

const mapStateToProps = state => ({
  dispatchers: dispatchersListSelector(state),
  backendUrl: backendUrlSelector(state),
});

const mapDispatchToProps = {
  fetchDispatchers,
  updateDispatcher,
  disableDispatcher,
};

export default connect(mapStateToProps, mapDispatchToProps)(DispatcherDetails);
