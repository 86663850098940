import { combineReducers } from 'redux';

import beacons from './beacons/reducer';
import cellular from './cellular/reducer';
import external from './external/reducer';
import phones from './phones/reducer';

const deviceReducer = combineReducers({
  beacons,
  cellular,
  external,
  phones,
});

export default deviceReducer;
