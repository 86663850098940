import {
  requestOptions,
  makeRequest,
} from './helper';

async function getAlertNotes(alertUUID) {
  const options = requestOptions('GET', {
    headers: { 'alert-uuid': alertUUID },
  });
  return makeRequest('notes/alert', options);
}

async function getEnterpriseNotes() {
  const options = requestOptions('GET');
  return makeRequest('notes', options);
}

async function postNote(note) {
  const options = requestOptions('POST', {
    body: note,
  });
  return makeRequest('notes', options);
}

async function putNote(noteUUID, note) {
  const options = requestOptions('PUT', {
    headers: { 'note-uuid': noteUUID },
    body: note,
  });
  return makeRequest(`note/${noteUUID}`, options);
}

async function deleteNote(noteUUID) {
  const options = requestOptions('DELETE', {
    headers: { 'note-uuid': noteUUID },
  });
  return makeRequest(`note/${noteUUID}`, options);
}

export {
  getAlertNotes,
  getEnterpriseNotes,
  postNote,
  putNote,
  deleteNote,
};
