import { connect } from 'react-redux';

import { fetchDisableAsFalseAlarmAlert } from 'store/alerts/active/thunks';

import AlertDetails from './components/AlertDetails';

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  setFalseAlert: fetchDisableAsFalseAlarmAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertDetails);
