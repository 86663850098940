import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';

import general from './general/reducer';
import alerts from './alerts/reducer';
import enterprise from './enterprise/reducer';
import devices from './devices/reducer';
import user from './user/reducer';
import dispatchers from './dispatchers/reducer';

const appReducer = combineReducers({
  general,
  alerts,
  enterprise,
  devices,
  routing,
  user,
  dispatchers,
});

export default appReducer;
