import { connect } from 'react-redux';

import userLoggedInSelector from 'store/user/selectors/userLoggedInSelector';
import {
  activeAlertsListSelector,
  activeAlertsFailedSelector,
} from 'store/alerts/active/selectors/activeAlertsSelector';

import {
  signOutUser,
} from 'store/user/thunks';

import PanelView from './components/PanelView';

const mapStateToProps = state => ({
  activeAlertsList: activeAlertsListSelector(state),
  haveAlertsFailed: activeAlertsFailedSelector(state),
  userLoggedIn: userLoggedInSelector(state),
});

const mapDispatchToProps = {
  signOutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(PanelView);
