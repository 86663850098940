import { createSelector } from 'reselect';
import enterpriseSelector from './enterpriseSelector';

const enterpriseConfigSelector = createSelector(
  enterpriseSelector,
  enterprise => enterprise.config || {},
);

const logoUrlSelector = createSelector(
  enterpriseConfigSelector,
  enterpriseConfig => enterpriseConfig.logoUrl || '',
);

const mapSelector = createSelector(
  enterpriseConfigSelector,
  config => config.map || {},
);

const zoomSelector = createSelector(
  mapSelector,
  map => map.zoom || 1,
);

const latitudeSelector = createSelector(
  mapSelector,
  map => map.latitude || 0,
);

const longitudeSelector = createSelector(
  mapSelector,
  map => map.longitude || 0,
);

export default enterpriseConfigSelector;

export {
  logoUrlSelector,
  mapSelector,
  zoomSelector,
  latitudeSelector,
  longitudeSelector,
};
