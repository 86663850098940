import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import styles from './SectionHeader.module.css';

function SectionHeader(props) {
  const { text, style } = props;

  return (
    <div className={classnames(styles.wrapper, style)}>
      {text}
    </div>
  );
}

SectionHeader.propTypes = {
  text: propTypes.string.isRequired,
  style: propTypes.string,
};

SectionHeader.defaultProps = {
  style: '',
};

export default SectionHeader;
