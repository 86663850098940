import { createSelector } from 'reselect';
import devicesSelector from 'store/devices/selectors/devicesSelector';

const DEFAULT_STATE = {};

const cellularSelector = createSelector(
  devicesSelector,
  devices => devices.cellular || DEFAULT_STATE,
);

const cellularListSelector = createSelector(
  cellularSelector,
  cellular => cellular.list || [],
);

const cellularMetaSelector = createSelector(
  cellularSelector,
  cellular => cellular.meta || {},
);

const cellularRequestingSelector = createSelector(
  cellularMetaSelector,
  meta => meta.requesting || false,
);

export {
  cellularListSelector,
  cellularMetaSelector,
  cellularRequestingSelector,
};

export default cellularSelector;
