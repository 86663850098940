import React, { Component } from 'react';
import propTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import { Form, Logo } from 'rmwc-components';

import { setTitleBar } from 'helpers/titleBar';

import styles from './UserAccess.module.css';

class UserAccess extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: '',
    };

    this.submitLoginFormData = this.submitLoginFormData.bind(this);
    this.onLinkClick = this.onLinkClick.bind(this);
  }

  componentDidMount() {
    setTitleBar();
  }

  onLinkClick(type) {
    const { backendUrl } = this.props;
    window.open(`${backendUrl}/auth/${type}/new`);
  }

  getLoginFormData() {
    return {
      email: {
        value: '',
        label: 'Email Address',
        type: 'email',
      },
      password: {
        value: '',
        label: 'Password',
        type: 'password',
      },
    };
  }

  submitLoginFormData(data) {
    const { signInUser } = this.props;
    if (data.email && data.password) {
      signInUser(data);
    } else {
      this.setState({ errorMessage: 'Enter something!' });
    }
  }

  renderErrorMessage() {
    const { errorMessage } = this.state;
    const { loginError } = this.props;
    const message = errorMessage || loginError;
    if (!message) return null;

    return (
      <div className={styles.errorMessage}>
        {message}
      </div>
    );
  }

  render() {
    const { userLoggedIn } = this.props;
    if (userLoggedIn) {
      return (<Redirect to="/dashboard" />);
    }

    return (
      <div className={styles.wrapper}>
        <Logo
          className={styles.logo}
          leftText="dispatch"
          rightText="center"
        />
        <div className={styles.formWrapper}>
          {this.renderErrorMessage()}
          <Form
            fieldInfo={this.getLoginFormData()}
            onSubmit={this.submitLoginFormData}
            submitLabel="Sign In"
            submitSize="full_width"
          />
          <div className={styles.linkWrapper}>
            <div onClick={() => this.onLinkClick('password')} className={styles.link}>Reset Password</div>
            <div onClick={() => this.onLinkClick('unlock')} className={styles.link}>Unlock Account</div>
            <div onClick={() => this.onLinkClick('confirmation')} className={styles.link}>Confirm Account</div>
          </div>
        </div>
      </div>
    );
  }
}

UserAccess.propTypes = {
  loginError: propTypes.string,

  userLoggedIn: propTypes.bool.isRequired,
  signInUser: propTypes.func.isRequired,
  backendUrl: propTypes.string.isRequired,
};

UserAccess.defaultProps = {
  loginError: '',
};

export default UserAccess;
