import {
  requestOptions,
  makeRequest,
} from './helper';

async function getBackendUrl() {
  const options = requestOptions('GET');

  return makeRequest('config', options, {
    path: 'api/general',
  });
}

async function getVersion() {
  const options = requestOptions('GET');
  return makeRequest('version', options, {
    path: '',
  });
}

export {
  getBackendUrl,
  getVersion,
};
