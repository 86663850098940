import { connect } from 'react-redux';

import {
  inactiveAlertsListSelector,
  inactiveAlertsRequestingSelector,
} from 'store/alerts/inactive/selectors/inactiveAlertsSelector';
import {
  alertNotesSelector,
} from 'store/alerts/notes/selectors/alertNotesSelector';

import {
  fetchEnterpriseInactiveAlerts,
} from 'store/alerts/inactive/thunks';
import {
  fetchEnterpriseNotes,
} from 'store/alerts/notes/thunks';

import AlertsHistory from './components/AlertsHistory';

const mapStateToProps = state => ({
  inactiveAlertsList: inactiveAlertsListSelector(state),
  allNotes: alertNotesSelector(state),
  requesting: inactiveAlertsRequestingSelector(state),
});

const mapDispatchToProps = {
  fetchEnterpriseInactiveAlerts,
  fetchEnterpriseNotes,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertsHistory);
