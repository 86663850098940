import Store from 'store/store';

import {
  requestOptions,
  makeRequest,
} from './helper';

async function getDispatchers() {
  return makeRequest('dispatchers', requestOptions());
}

async function postDispatcher(dispatcher) {
  const options = requestOptions('POST', {
    body: {
      dispatcher,
      enterprise_guid: Store.enterpriseId,
    },
  });
  const API = 'dispatchers/create';
  return makeRequest(API, options);
}

async function putDispatcher(dispatcher) {
  const options = requestOptions('PUT', {
    body: dispatcher,
  });
  const API = `dispatchers/${dispatcher.guid}`;
  return makeRequest(API, options);
}

async function deleteDispatcher(dispatcherGuid) {
  const options = requestOptions('DELETE', {
    body: {
      guid: dispatcherGuid,
      enterprise_guid: Store.enterpriseId,
    },
  });
  const API = `dispatchers/${dispatcherGuid}`;
  return makeRequest(API, options);
}

export {
  getDispatchers,
  postDispatcher,
  putDispatcher,
  deleteDispatcher,
};
