import { connect } from 'react-redux';

import {
  phonesListSelector,
  phonesRequestingSelector,
} from 'store/devices/phones/selectors/phonesSelector';

import {
  fetchPhones,
  removePhone,
} from 'store/devices/phones/thunks';

import Phones from './components/Phones';

const mapStateToProps = state => ({
  phones: phonesListSelector(state),
  requesting: phonesRequestingSelector(state),
});

const mapDispatchToProps = {
  fetchPhones,
  removePhone,
};

export default connect(mapStateToProps, mapDispatchToProps)(Phones);
