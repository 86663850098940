import { INFORMATION } from 'store/user/const';
import { INACTIVE_ALERTS } from './const';

const initialState = {
  details: {},
  order: [],
  meta: {
    etag: '',
    requesting: false,
    errorMessage: '',
  },
};

function alertsReducer(state = initialState, action) {
  switch (action.type) {
    case INACTIVE_ALERTS.REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          errorMessage: '',
          requesting: true,
        },
      };

    case INACTIVE_ALERTS.RECEIVE:
      return {
        ...state,
        ...action.response.data,
        meta: {
          ...initialState.meta,
          etag: action.response.etag,
        },
      };

    case INACTIVE_ALERTS.REJECT:
      return {
        ...initialState,
        meta: {
          ...initialState.meta,
          errorMessage: action.error,
        },
      };

    case INACTIVE_ALERTS.END:
      return {
        ...state,
        meta: {
          ...state.meta,
          requesting: false,
        },
      };

    case INFORMATION.CLEAR: {
      return initialState;
    }

    default:
      return state;
  }
}

export default alertsReducer;
