import React, { Component } from 'react';
import propTypes from 'prop-types';

import { Locale } from 'rmwc-libraries';

import { alertHelpers } from 'rmwc-helpers';
const { formatCSVAlerts } = alertHelpers;

import Store from 'store/store';
import User from 'lib/user';

import TableList from 'modules/components/TableList';

class ActiveAlerts extends Component {
  constructor(props) {
    super(props);

    this.setAsFalseAlert = this.setAsFalseAlert.bind(this);
  }

  componentDidMount() {
    const { fetchEnterpriseNotes } = this.props;
    fetchEnterpriseNotes();
  }

  setAsFalseAlert(id) {
    const { fetchDisableAsFalseAlarmAlert } = this.props;
    fetchDisableAsFalseAlarmAlert(id);
  }

  getTableData() {
    const { activeAlertsList = [] } = this.props;

    const tableData = {
      cellDataList: activeAlertsList,
      cellDefinitions: [
        {
          header: {
            label: Locale.t('table_label_id'),
          },
          text: {
            key: 'id',
          },
          link: {
            to: '/alerts/active/#KEY#',
            keys: ['uuid'],
            style: 'text',
            onclick: (url) => {
              Store.history.push(url);
            },
          },
        },
        {
          header: {
            label: Locale.t('table_label_status'),
          },
          text: {
            key: 'status',
            formatter: data => data.replace('_', ' ').toUpperCase(),
            color: (formattedData) => {
              if (formattedData === 'DEACTIVATED') return 'green';
              if (formattedData === 'ACTIVE') return 'red';
              return 'blue';
            },
          },
        },
        {
          header: {
            label: Locale.t('table_label_device'),
          },
          text: {
            key: 'firstname',
          },
        },
        {
          header: {
            label: Locale.t('table_label_location'),
          },
          text: {
            key: 'lastname',
          },
        },
        {
          header: {
            label: Locale.t('table_label_date_time'),
            span: 3,
          },
          text: {
            key: 'createdAt',
          },
        },
        {
          text: {
            value: Locale.t('button_label_view'),
          },
          link: {
            to: '/alerts/active/#KEY#',
            keys: ['uuid'],
            style: 'button',
            onclick: (url) => {
              Store.history.push(url);
            },
          },
        },
        {
          text: {
            value: Locale.t('set_as_false_button'),
          },
          button: {
            color: 'red',
            confirmationText: Locale.t('set_as_false_confirmation'),
            handler: {
              key: 'id',
              func: this.setAsFalseAlert,
            },
          },
          locked: User.isNotOperator(),
        },
      ],
    };

    return tableData;
  }

  render() {
    const {
      requesting,
    } = this.props;

    return (
      <TableList
        requesting={requesting}
        data={this.getTableData()}
        download={Locale.t('csv_title_active_alerts')}
        noDataMessage={Locale.t('no_active_alerts')}
        csvFormatter={() => {
          const { activeAlertsList, allNotes } = this.props;
          return formatCSVAlerts(activeAlertsList, allNotes);
        }}
      />
    );
  }
}

ActiveAlerts.propTypes = {
  activeAlertsList: propTypes.array,
  allNotes: propTypes.object,
  requesting: propTypes.bool,

  fetchDisableAsFalseAlarmAlert: propTypes.func.isRequired,
  fetchEnterpriseNotes: propTypes.func.isRequired,
};

ActiveAlerts.defaultProps = {
  activeAlertsList: [],
  allNotes: {},
  requesting: false,
};

export default ActiveAlerts;
