import { createSelector } from 'reselect';
import devicesSelector from 'store/devices/selectors/devicesSelector';

const DEFAULT_STATE = {};

const beaconsSelector = createSelector(
  devicesSelector,
  devices => devices.beacons || DEFAULT_STATE,
);

const beaconsListSelector = createSelector(
  beaconsSelector,
  beacons => beacons.list || [],
);

const beaconsMetaSelector = createSelector(
  beaconsSelector,
  beacons => beacons.meta || {},
);

const beaconsRequestingSelector = createSelector(
  beaconsMetaSelector,
  meta => meta.requesting || false,
);

export {
  beaconsListSelector,
  beaconsMetaSelector,
  beaconsRequestingSelector,
};

export default beaconsSelector;
