import { createSelector } from 'reselect';
import userSelector from './userSelector';

const DEFAULT_STATE = {};

const userInfoSelector = createSelector(
  userSelector,
  user => user.info || DEFAULT_STATE,
);

export default userInfoSelector;
