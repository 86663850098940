import Dashboard from 'modules/pages/Dashboard';
import ActiveAlerts from 'modules/pages/ActiveAlerts';
import AlertsHistory from 'modules/pages/AlertsHistory';
import AlertDetails from 'modules/pages/AlertDetails';
import Dispatchers from 'modules/pages/Dispatchers';
import DispatcherDetails from 'modules/pages/DispatcherDetails';
import EnterpriseProperties from 'modules/pages/EnterpriseProperties';
import Beacons from 'modules/pages/Beacons';
import ApplicationSettings from 'modules/pages/ApplicationSettings';
import LTEDevices from 'modules/pages/LTEDevices';
import SystemInfo from 'modules/pages/SystemInfo';
import Phones from 'modules/pages/Phones';
import PhoneDetails from 'modules/pages/PhoneDetails';

import { USER_ROLES } from 'lib/user';

const ROUTES = {
  '/dashboard': {
    title: 'Dashboard',
    component: Dashboard,
    meta: {},
  },
  '/alerts/active': {
    title: 'Active Alerts',
    component: ActiveAlerts,
    meta: {
      highlight: {
        '/alerts/active/:uuid': true,
      },
    },
  },
  '/alerts/active/:uuid': {
    title: 'Alert Details',
    component: AlertDetails,
    meta: {},
  },
  '/alerts/history': {
    title: 'Alerts History',
    component: AlertsHistory,
    meta: {
      highlight: {
        '/alerts/history/:uuid': true,
      },
    },
  },
  '/alerts/history/:uuid': {
    title: 'Alert Details',
    component: AlertDetails,
    meta: {},
  },
  '/devices': {
    title: 'Devices',
    component: Phones,
    requiredRole: USER_ROLES.operator,
    meta: {
      subroute: true,
      highlight: {
        '/devices/:id': true,
      },
    },
  },
  '/devices/:id': {
    title: 'Device Details',
    component: PhoneDetails,
    requiredRole: USER_ROLES.operator,
    meta: {},
  },
  '/beacons': {
    title: 'Beacons',
    component: Beacons,
    requiredRole: USER_ROLES.operator,
    meta: {
      subroute: true,
    },
  },
  '/lte-devices': {
    title: 'LTE Devices',
    component: LTEDevices,
    requiredRole: USER_ROLES.operator,
    meta: {
      subroute: true,
    },
  },
  '/dispatchers': {
    title: 'Dispatchers',
    component: Dispatchers,
    requiredRole: USER_ROLES.operator,
    meta: {
      highlight: {
        '/dispatchers/:id': true,
      },
    },
  },
  '/dispatchers/:id': {
    title: 'Dispatcher Details',
    component: DispatcherDetails,
    requiredRole: USER_ROLES.operator,
    meta: {},
  },
  '/enterprise-properties': {
    title: 'Enterprise Properties',
    component: EnterpriseProperties,
    requiredRole: USER_ROLES.operator,
    meta: {},
  },
  '/application-settings': {
    title: 'Settings',
    component: ApplicationSettings,
    meta: {},
  },
  '/system-information': {
    title: 'System Information',
    component: SystemInfo,
    meta: {},
  },
};

export default ROUTES;
