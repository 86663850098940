import { INFORMATION } from 'store/user/const';
import {
  TABLE_DATA,
  BACKEND_URL,
  VERSION,
} from './const';

const initialState = {
  backendUrl: '',
  tables: {},
  version: {},
};

function generalReducer(state = initialState, action) {
  switch (action.type) {
    case TABLE_DATA.RECEIVE: {
      const newTables = state.tables;
      const { name, data } = action.response;
      newTables[name] = data;
      return {
        ...state,
        tables: newTables,
      };
    }

    case TABLE_DATA.CLEAR: {
      return {
        ...state,
        tables: {},
      };
    }

    case BACKEND_URL.RECEIVE: {
      const { backendUrl } = action.response;
      return {
        ...state,
        backendUrl,
      };
    }

    case BACKEND_URL.REJECT: {
      return {
        ...state,
        backendUrl: '',
      };
    }

    case VERSION.RECEIVE: {
      const { response: newVersion } = action;
      delete newVersion.status;
      return {
        ...state,
        version: newVersion,
      };
    }

    case VERSION.REJECT: {
      return {
        ...state,
        version: {},
      };
    }

    case INFORMATION.CLEAR: {
      return {
        ...initialState,
        backendUrl: state.backendUrl,
      };
    }

    default:
      return state;
  }
}

export default generalReducer;
