import { createSelector } from 'reselect';
import alertsSelector from 'store/alerts/selectors/alertsSelector';

const inactiveAlertsSelector = createSelector(
  alertsSelector,
  alerts => alerts.inactive || {},
);

const inactiveAlertsDetailsSelector = createSelector(
  inactiveAlertsSelector,
  inactiveAlerts => inactiveAlerts.details || {},
);

const inactiveAlertsOrderSelector = createSelector(
  inactiveAlertsSelector,
  inactiveAlerts => inactiveAlerts.order || [],
);

const inactiveAlertsListSelector = createSelector(
  inactiveAlertsDetailsSelector,
  inactiveAlertsOrderSelector,
  (details, order) => order.map(id => details[id]),
);

const inactiveAlertsMetaSelector = createSelector(
  inactiveAlertsSelector,
  inactiveAlerts => inactiveAlerts.meta || {},
);

const inactiveAlertsRequestingSelector = createSelector(
  inactiveAlertsMetaSelector,
  meta => meta.requesting || false,
);

export {
  inactiveAlertsDetailsSelector,
  inactiveAlertsListSelector,
  inactiveAlertsMetaSelector,
  inactiveAlertsRequestingSelector,
};
