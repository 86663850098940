import { INFORMATION } from 'store/user/const';
import {
  ALERT_NOTES,
  ENTERPRISE_NOTES,
} from './const';

const initialState = {
  byAlertUUID: {},
  meta: {
    etag: '',
    requesting: false,
    errorMessage: '',
  },
};

function notesReducer(state = initialState, action) {
  const { response = {} } = action;
  const { data } = response;

  switch (action.type) {
    case ALERT_NOTES.REQUEST: {
      return {
        ...state,
        meta: {
          ...state.meta,
          errorMessage: '',
          requesting: true,
        },
      };
    }

    case ALERT_NOTES.RECEIVE: {
      const { alert_uuid } = response;
      const { byAlertUUID } = state;
      byAlertUUID[alert_uuid] = data;
      return {
        ...state,
        byAlertUUID,
        meta: {
          ...initialState.meta,
        },
      };
    }

    case ENTERPRISE_NOTES.RECEIVE: {
      return {
        ...state,
        byAlertUUID: data,
        meta: {
          ...initialState.meta,
        },
      };
    }

    case ALERT_NOTES.REJECT: {
      return {
        ...initialState, // One note fail will clear all notes, but thats okay
        meta: {
          ...initialState.meta,
          errorMessage: action.error,
        },
      };
    }

    case INFORMATION.CLEAR: {
      return initialState;
    }

    default:
      return state;
  }
}

export default notesReducer;
