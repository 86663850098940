import { createSelector } from 'reselect';

import { activeAlertsDetailsSelector } from 'store/alerts/active/selectors/activeAlertsSelector';
import { inactiveAlertsDetailsSelector } from 'store/alerts/inactive/selectors/inactiveAlertsSelector';

const allAlertsSelector = createSelector(
  activeAlertsDetailsSelector,
  inactiveAlertsDetailsSelector,
  (activeAlerts, inactiveAlerts) => ({
    ...activeAlerts,
    ...inactiveAlerts,
  }),
);

export default allAlertsSelector;
