import { createSelector } from 'reselect';
import enterpriseSelector from './enterpriseSelector';

const DEFAULT_STATE = {};

const integrationsSelector = createSelector(
  enterpriseSelector,
  enterprise => enterprise.integrations || DEFAULT_STATE,
);

const integrationsMetaSelector = createSelector(
  integrationsSelector,
  integrations => integrations.meta || {},
);

export default integrationsSelector;

export {
  integrationsMetaSelector,
};
