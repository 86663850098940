import { connect } from 'react-redux';

import userInfoSelector from 'store/user/selectors/userInfoSelector';
import {
  alertNotesSelector,
} from 'store/alerts/notes/selectors/alertNotesSelector';

import {
  fetchAlertNotes,
  createAlertNote,
  updateAlertNote,
  removeAlertNote,
} from 'store/alerts/notes/thunks';

import Notes from './components/Notes';

const mapStateToProps = state => ({
  userInfo: userInfoSelector(state),
  allNotes: alertNotesSelector(state),
});

const mapDispatchToProps = {
  fetchAlertNotes,
  createAlertNote,
  updateAlertNote,
  removeAlertNote,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
