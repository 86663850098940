import { connect } from 'react-redux';

import {
  beaconsListSelector,
  beaconsRequestingSelector,
} from 'store/devices/beacons/selectors/beaconsSelector';
import {
  isProdEnvSelector,
} from 'store/general/selectors/generalSelector';

import {
  fetchBeacons,
} from 'store/devices/beacons/thunks';

import Beacons from './components/Beacons';

const mapStateToProps = state => ({
  beacons: beaconsListSelector(state),
  isProdEnv: isProdEnvSelector(state),
  requesting: beaconsRequestingSelector(state),
});

const mapDispatchToProps = {
  fetchBeacons,
};

export default connect(mapStateToProps, mapDispatchToProps)(Beacons);
