import React from 'react';
import propTypes from 'prop-types';
import styles from './FocusView.module.css';

function FocusView(props) {
  const { children } = props;

  return (
    <div className={styles.body}>
      <div className={styles.flexContainer}>
        {children}
      </div>
    </div>
  );
}

FocusView.propTypes = {
  children: propTypes.node.isRequired,
};

export default FocusView;
