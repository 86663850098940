import {
  configureActions,
  checkError,
  checkNoUpdate,
} from 'store/helpers';

import {
  getEnterpriseActiveAlerts,
  putEnterpriseDisableAlert,
} from 'lib/api/alerts';
import {
  fetchEnterpriseInactiveAlerts,
} from 'store/alerts/inactive/thunks';
import {
  activeAlertsMetaSelector,
} from './selectors/activeAlertsSelector';

import {
  ACTIVE_ALERTS,
  DISABLE_ALERT,
} from './const';

const activeAlertsActions = configureActions(ACTIVE_ALERTS);
export const fetchEnterpriseActiveAlerts = () => async (dispatch, getState) => {
  const {
    etag = '',
    requesting = false,
  } = activeAlertsMetaSelector(getState());

  if (requesting) return;
  dispatch(activeAlertsActions.request());
  const response = await getEnterpriseActiveAlerts({ etag });
  if (checkError(dispatch, response, activeAlertsActions.reject)) return;
  if (checkNoUpdate(dispatch, etag, response, activeAlertsActions.end)) return;
  dispatch(activeAlertsActions.receive(response));
};

const disableAlertActions = configureActions(DISABLE_ALERT);
export const fetchDisableAsFalseAlarmAlert = id => async (dispatch) => {
  dispatch(disableAlertActions.request(id));
  const response = await putEnterpriseDisableAlert(id, 'false_alarm');
  if (checkError(dispatch, response, disableAlertActions.reject)) return;
  dispatch(disableAlertActions.receive(id));
  dispatch(fetchEnterpriseActiveAlerts());
  dispatch(fetchEnterpriseInactiveAlerts());
};
