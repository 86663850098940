import { connect } from 'react-redux';

import phonesSelector from 'store/devices/phones/selectors/phonesSelector';

import {
  fetchPhones,
  updatePhone,
  updatePhoneExternalDeviceIds,
} from 'store/devices/phones/thunks';

import PhoneDetails from './components/PhoneDetails';

const mapStateToProps = state => ({
  phones: phonesSelector(state),
});

const mapDispatchToProps = {
  fetchPhones,
  updatePhone,
  updatePhoneExternalDeviceIds,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneDetails);
