import { connect } from 'react-redux';

import enterpriseConfigSelector from 'store/enterprise/selectors/enterpriseConfigSelector';
import userLoggedInSelector from 'store/user/selectors/userLoggedInSelector';
import userRoleSelector from 'store/user/selectors/userRoleSelector';
import userInfoSelector from 'store/user/selectors/userInfoSelector';

import { activeAlertsListSelector } from 'store/alerts/active/selectors/activeAlertsSelector';
import { inactiveAlertsListSelector } from 'store/alerts/inactive/selectors/inactiveAlertsSelector';

import {
  fetchBackendUrl,
  fetchVersion,
} from 'store/general/thunks';

import {
  fetchEnterpriseConfig,
  fetchEnterpriseIntegrations,
} from 'store/enterprise/thunks';

import {
  fetchEnterpriseActiveAlerts,
} from 'store/alerts/active/thunks';
import {
  fetchEnterpriseInactiveAlerts,
} from 'store/alerts/inactive/thunks';

import {
  signOutUser,
  addUserData,
} from 'store/user/thunks';

import App from './components/App';

const mapStateToProps = state => ({
  activeAlertsList: activeAlertsListSelector(state),
  inactiveAlertsList: inactiveAlertsListSelector(state),
  enterpriseConfig: enterpriseConfigSelector(state),
  userLoggedIn: userLoggedInSelector(state),
  userRole: userRoleSelector(state),
  userInfo: userInfoSelector(state),
});

const mapDispatchToProps = {
  fetchBackendUrl,
  fetchEnterpriseActiveAlerts,
  fetchEnterpriseInactiveAlerts,
  fetchEnterpriseConfig,
  fetchEnterpriseIntegrations,
  fetchVersion,
  signOutUser,
  addUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
