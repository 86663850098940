import React, { Component } from 'react';
import propTypes from 'prop-types';

import TableList from 'modules/components/TableList';
import Store from 'store/store';

import User from 'lib/user';

class Phones extends Component {
  constructor(props) {
    super(props);

    this.deletePhone = this.deletePhone.bind(this);
  }

  componentDidMount() {
    const { fetchPhones } = this.props;
    fetchPhones();
  }

  getTableData() {
    const { phones = [] } = this.props;
    const tableData = {
      title: 'Devices List',
      cellDataList: phones,
      cellDefinitions: [
        {
          header: {
            label: 'Device',
          },
          text: {
            key: 'firstName',
          },
          link: {
            style: 'text',
            to: '/devices/#KEY#',
            keys: ['guid'],
            onclick: (url) => {
              Store.history.push(url);
            },
          },
        },
        {
          header: {
            label: 'Location',
          },
          text: {
            key: 'lastName',
          },
        },
        {
          header: {
            label: 'Created On',
            span: 3,
          },
          text: {
            key: 'createdAt',
          },
        },
        {
          text: {
            value: 'edit',
          },
          link: {
            to: '/devices/#KEY#',
            keys: ['guid'],
            style: 'button',
            onclick: (url) => {
              Store.history.push(url);
            },
          },
          locked: User.isNotAdmin(),
        },
      ],
    };
    return tableData;
  }

  deletePhone(phoneGuid) {
    const { fetchPhones, removePhone } = this.props;
    removePhone(phoneGuid).then(() => {
      fetchPhones();
    });
  }

  render() {
    const {
      requesting,
    } = this.props;

    return (
      <TableList
        requesting={requesting}
        data={this.getTableData()}
        download="Phones"
        csvFormatter={() => {
          const { phones = [] } = this.props;
          return phones.map((phone) => {
            return {
              'GUID': phone.guid,
              'Device': phone.firstName,
              'Location': phone.lastName,
              'Email': phone.email,
              'Mobile Phone Number': phone.mobilePhoneNumber,
              'External Device IDs': (phone.externalDeviceIds || []).filter(id => id.trim()).join(', '),
              'Associated At (UTC)': (new Date(phone.associatedAt)).toUTCString(),
              'Created At (UTC)': (new Date(phone.createdAt)).toUTCString(),
            };
          });
        }}
      />
    );
  }
}

Phones.propTypes = {
  phones: propTypes.array,
  requesting: propTypes.bool,

  fetchPhones: propTypes.func.isRequired,
  removePhone: propTypes.func.isRequired,
};

Phones.defaultProps = {
  phones: [],
  requesting: false,
};

export default Phones;
