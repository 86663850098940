import { createSelector } from 'reselect';
import devicesSelector from 'store/devices/selectors/devicesSelector';

const DEFAULT_STATE = {};

const lteDevicesSelector = createSelector(
  devicesSelector,
  devices => devices.lteDevices || DEFAULT_STATE,
);

const lteDevicesListSelector = createSelector(
  lteDevicesSelector,
  lteDevices => lteDevices.list || [],
);

const lteDevicesMetaSelector = createSelector(
  lteDevicesSelector,
  lteDevices => lteDevices.meta || {},
);

const lteDevicesRequestingSelector = createSelector(
  lteDevicesMetaSelector,
  meta => meta.requesting || false,
);

export {
  lteDevicesListSelector,
  lteDevicesMetaSelector,
  lteDevicesRequestingSelector,
};

export default lteDevicesSelector;
