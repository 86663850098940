import Store from 'store/store';
import userInfoSelector from 'store/user/selectors/userInfoSelector';

import { Locale } from 'rmwc-libraries';
import cookies from 'lib/cookies';

import { stringHelpers } from 'rmwc-helpers';
const { trimPayload } = stringHelpers;

const rmdcCookie = () => cookies.get('rmdc') || {};

const urlPath = process.env.NODE_ENV === 'development'
  ? 'http://localhost:5001'
  : '';

const requestOptions = (method, opts = {}) => {
  const requestParams = Object.assign({
    client: null,
    uid: null,
    accessToken: null,
    enterpriseId: null,
    guid: null,
  }, rmdcCookie(), userInfoSelector(Store.state));

  const {
    client,
    uid,
    accessToken,
    enterpriseId,
    guid,
  } = requestParams;

  // TODO: Replace client-version with something more meaningful
  // Should probably create a /version route as well
  // Electron DC used cache-control: no-cache header
  const requestOpts = {
    method: method || 'GET',
    credentials: opts.credentials || 'include',
    headers: {
      'cache-control': 'no-cache',
      'Pragma': 'no-cache',
      'access-token': accessToken,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'client': client,
      'uid': uid,
      'enterpriseuuid': enterpriseId,
      'enterprise-uuid': enterpriseId,
      'user-uuid': guid,
      'provider': '', // Taken from DC Electron call, it was empty
      'client-version': 'dc.1.4.0', // Taken from DC Electron call, not sure it's important
    },
  };

  // Any headers passed in through opts should overwrite the default headers
  Object.assign(requestOpts.headers, opts.headers || {});

  if (opts.body) {
    // I can't think of any payload from the DC where trimming wouldn't be wanted
    // so it should be fine to call it on everything
    requestOpts.body = JSON.stringify(trimPayload(opts.body));
  }

  return requestOpts;
};

const requestValidator = (status, response = {}) => {
  if (response.error) {
    const { message = {} } = response;
    if (typeof message === 'string') return message;
    return message.error || (message.errors || [])[0] || Locale.t('error_service_generic');
  }
  return null;
};

const makeRequest = async (api, requestOpts, opts = {}) => {
  try {
    let { path } = opts;
    if (typeof path !== 'string') path = 'api/enterprise';
    let URL = null;
    if (!path) { // path is an empty string
      URL = `${urlPath}/${api}`;
    } else {
      URL = `${urlPath}/${path}/${api}`;
    }

    const request = await fetch(URL, requestOpts);
    const { status = 500 } = request;

    // 304s will throw a parse error but I don't want them to be rejected as errors
    let response = null;
    try {
      response = await request.json();
    } catch (e) {
      response = {};
    }
    response.status = status;

    // requestValidator returns errors to throw if there was a problem
    const validator = opts.requestValidator || requestValidator;
    const error = validator(status, response);
    // If a validator determined an error, construct an error payload
    if (error) {
      response = {
        status,
        error: true,
        message: error,
      };
    }

    if (response.data && opts.dataParser) {
      response.data = opts.dataParser(response.data) || response.data;
    }

    return response;
  } catch (error) {
    return error;
  }
};

export {
  urlPath,
  requestOptions,
  makeRequest,
  requestValidator,
};
