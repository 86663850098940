import { Logger } from 'rmwc-libraries';

import cookies from 'lib/cookies';
import get from 'lodash/get';

// NOTE:
// Uses localStorage to store current settings
// localStorage is unique for each protocol://host:port

const SETTINGS_SCHEMA = {
  newAlertNotifications: {
    default: true,
    label: 'Display Desktop Notifications for new alerts',
    type: 'checkbox',
  },
  alertDeactivationNotifications: {
    default: true,
    label: 'Display Desktop Notifications for deactivated alerts',
    type: 'checkbox',
  },
  zoomOnPinClick: {
    default: true,
    label: 'Zoom when clicking on a pin',
    type: 'checkbox',
  },
  zoomFitNewAlerts: {
    default: true,
    label: 'Zoom and fit all alerts for new alerts',
    type: 'checkbox',
  },
  alertSoundEnabled: {
    default: true,
    label: 'Play alert sound for new alerts',
    type: 'checkbox',
  },
  alertSoundRepeat: {
    default: false,
    label: 'Repeat the alarm sound until mouse movement or click/touch is detected',
    type: 'checkbox',
  },
};

class Settings {
  initialize() {
    const guid = this.getUserGuid();
    const settings = this.getUserSettings();

    Object.keys(SETTINGS_SCHEMA).forEach((setting) => {
      if (typeof settings[setting] !== 'boolean') {
        settings[setting] = SETTINGS_SCHEMA[setting].default;
      }
    });
    localStorage.setItem(guid, JSON.stringify(settings));

    Logger.debug('Initial settings', settings);
  }

  getUserGuid() {
    const rmdcCookie = cookies.get('rmdc');
    return get(rmdcCookie, 'guid', '');
  }

  getUserSettings() {
    return JSON.parse(localStorage.getItem(this.getUserGuid()) || '{}');
  }

  getValue(key) {
    if (!SETTINGS_SCHEMA[key]) {
      Logger.error(`Settings >> You have requested an unsupported key: ${key}`);
    }

    const settings = this.getUserSettings();
    const value = settings[key];
    // Deal with boolean settings
    if (typeof SETTINGS_SCHEMA[key].default === 'boolean') return !!value;
    // Deal with non-boolean settings;
    return value || SETTINGS_SCHEMA[key];
  }

  setValue(key, value) {
    Logger.debug(`Settings "${key}" changing to ${value}`);
    const guid = this.getUserGuid();
    const settings = this.getUserSettings();
    // If the value matches "true" or "false" exactly, convert it to a boolean
    settings[key] = (value.toString().match(/^true$|^false$/)) ? !!value : value;
    localStorage.setItem(guid, JSON.stringify(settings));
  }
}

export default new Settings();

export {
  SETTINGS_SCHEMA,
};
