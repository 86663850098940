import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';

import { Form } from 'rmwc-components';
import TableList from 'modules/components/TableList';
import Store from 'store/store';

import User from 'lib/user';

import { validationHelpers } from 'rmwc-helpers';
const { isValidEmail, emailAlreadyExists } = validationHelpers;

class Dispatchers extends Component {
  constructor(props) {
    super(props);

    this.submitNewDispatcher = this.submitNewDispatcher.bind(this);
    this.validateNewDispatcher = this.validateNewDispatcher.bind(this);
  }

  componentDidMount() {
    const { fetchDispatchers } = this.props;
    fetchDispatchers();
  }

  componentDidUpdate(prevProps) {
    const prevDispatcherCreated = prevProps.dispatcherCreated;
    const {
      dispatcherCreated,
      fetchDispatchers,
    } = this.props;
    if (dispatcherCreated && prevDispatcherCreated !== dispatcherCreated) {
      fetchDispatchers();
    }
  }

  getDispatcherFormData() {
    return {
      name: {
        label: 'Name',
        size: 'third_width',
      },
      email: {
        label: 'Email',
        size: 'third_width',
      },
      nickname: {
        label: 'Nickname',
        size: 'third_width',
      },
    };
  }

  getTableData() {
    const {
      dispatchers = [],
    } = this.props;

    const tableData = {
      title: 'Dispatchers List',
      cellDataList: dispatchers,
      cellDefinitions: [
        {
          header: {
            label: 'Name',
          },
          text: {
            key: 'name',
          },
          link: {
            style: 'text',
            to: '/dispatchers/#KEY#',
            keys: ['guid'],
            onclick: (url) => {
              Store.history.push(url);
            },
          },
        },
        {
          header: {
            label: 'Email',
          },
          text: {
            key: 'email',
          },
        },
        {
          header: {
            label: 'Status',
          },
          text: {
            key: 'isDisabled',
            formatter: (data) => {
              if (data) return 'DISABLED';
              return 'ENABLED';
            },
            color: (formattedData) => {
              if (formattedData === 'DISABLED') return 'red';
              return 'green';
            },
          },
        },
        {
          header: {
            label: 'Role',
            span: 2,
          },
          text: {
            key: 'dispatcherType',
          },
        },
        {
          text: {
            value: 'edit',
          },
          link: {
            to: '/dispatchers/#KEY#',
            keys: ['guid'],
            style: 'button',
            onclick: (url) => {
              Store.history.push(url);
            },
          },
          locked: User.isNotAdmin(),
        },
      ],
    };
    return tableData;
  }

  submitNewDispatcher(data) {
    const { createDispatcher } = this.props;
    return createDispatcher(data);
  }

  validateNewDispatcher(data) {
    const { dispatchers } = this.props;
    const {
      name,
      email,
    } = data;
    const errors = {};

    if (!name.trim()) {
      errors.name = 'Name must not be empty';
    }

    const trimmedEmail = email.trim();
    if (!isValidEmail(trimmedEmail)) {
      errors.email = 'Please provide a valid email';
    } else if (emailAlreadyExists(trimmedEmail, dispatchers)) {
      // TODO: A server error does come through for existing emails
      // but this is faster and the message looks nicer
      errors.email = 'A dispatcher with this email already exists';
    }

    return errors;
  }

  render() {
    const {
      requesting,
      createMeta = {},
    } = this.props;
    const { errorMessage } = createMeta;

    return (
      <Fragment>
        <Form
          collapsible
          name="Create New Dispatcher"
          fieldInfo={this.getDispatcherFormData()}
          validate={this.validateNewDispatcher}
          onSubmit={this.submitNewDispatcher}
          submitLabel="Add Dispatcher"
          errorMessage={errorMessage}
          resetOnSuccess
          disabled={User.isNotAdmin()}
        />
        <TableList
          requesting={requesting}
          data={this.getTableData()}
          download="Dispatchers"
        />
      </Fragment>
    );
  }
}

Dispatchers.propTypes = {
  dispatchers: propTypes.array,
  dispatcherCreated: propTypes.bool,
  requesting: propTypes.bool,
  createMeta: propTypes.object,

  fetchDispatchers: propTypes.func.isRequired,
  createDispatcher: propTypes.func.isRequired,
};

Dispatchers.defaultProps = {
  dispatchers: [],
  dispatcherCreated: false,
  requesting: false,
  createMeta: {},
};

export default Dispatchers;
