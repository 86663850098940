function setTitleBar(activeAlertsList = []) {
  const favicon = document.getElementById('favicon');
  const title = document.getElementById('document_title');
  if (!favicon || !title) return;

  const titleText = 'React Mobile Dispatch Center';
  const faviconHref = favicon.getAttribute('href');
  if (activeAlertsList.length) {
    if (faviconHref.indexOf('rmdc_favicon_alert') === -1) {
      favicon.setAttribute('href', faviconHref.replace('rmdc_favicon', 'rmdc_favicon_alert'));
    }
    title.innerHTML = `(${activeAlertsList.length}) ${titleText}`;
  } else {
    if (faviconHref.indexOf('rmdc_favicon_alert') !== -1) {
      favicon.setAttribute('href', faviconHref.replace('rmdc_favicon_alert', 'rmdc_favicon'));
    }
    title.innerHTML = titleText;
  }
}

export {
  setTitleBar,
};
