import { createSelector } from 'reselect';
import devicesSelector from 'store/devices/selectors/devicesSelector';

const DEFAULT_STATE = {};

const phonesSelector = createSelector(
  devicesSelector,
  devices => devices.phones || DEFAULT_STATE,
);

const phonesListSelector = createSelector(
  phonesSelector,
  phones => phones.all.map(phoneGuid => phones.byId[phoneGuid]) || [],
);

const phonesMetaSelector = createSelector(
  phonesSelector,
  phones => phones.meta || {},
);

const phonesRequestingSelector = createSelector(
  phonesMetaSelector,
  meta => meta.requesting || false,
);

export {
  phonesListSelector,
  phonesMetaSelector,
  phonesRequestingSelector,
};

export default phonesSelector;
