import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'react-router-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import cookies from 'lib/cookies';

import appReducer from 'store/appReducer';

import userEnterpriseIdSelector from 'store/user/selectors/userEnterpriseIdSelector';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

class Store {
  constructor() {
    this.props = {
      store: null,
      history: null,
    };

    this.store = createStore(appReducer, composeEnhancers(applyMiddleware(thunk)));

    const browserHistory = createBrowserHistory();
    this.history = syncHistoryWithStore(browserHistory, this.store);
  }

  set store(newStore) {
    this.props.store = newStore;
  }

  get store() {
    return this.props.store;
  }

  set history(newHistory) {
    this.props.history = newHistory;
  }

  get history() {
    return this.props.history;
  }

  get state() {
    if (!this.store) return {};
    return this.store.getState();
  }

  get enterpriseId() {
    const rmdcCookie = cookies.get('rmdc') || {};
    return userEnterpriseIdSelector(this.state) || rmdcCookie.enterpriseId || null;
  }
}

export default new Store();
