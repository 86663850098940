import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';

import { alertHelpers, timeHelpers } from 'rmwc-helpers';
const { formatLocation } = alertHelpers;
const { getTimeFromDate } = timeHelpers;

import styles from './ActiveAlerts.module.css';

class ActiveAlerts extends Component {
  render() {
    const {
      alert: selectedAlert,
      activeAlerts = [],
      onAlertClick,
    } = this.props;

    return (
      <Fragment>
        <h3 className={styles.header}>Active Alerts: {activeAlerts.length}</h3>
        <div className={styles.list}>
          {activeAlerts.map((alert, index) => {
            let alertClass = styles.alert;
            if (selectedAlert && alert.uuid === selectedAlert.uuid) {
              alertClass = classnames(styles.alert, styles.selectedAlert);
            }

            return (
              <div key={alert.uuid} className={alertClass} onClick={() => { onAlertClick(alert.uuid); }}>
                <div className={styles.markerNumber}><div>{index + 1}</div></div>
                <div className={styles.details}>
                  <div className={styles.deviceAndProperty}>{alert.firstname} - {alert.lastname}</div>
                  <div><span>Time:</span>{getTimeFromDate(alert.createdAt)}</div>
                  <div><span>Last Known Location:</span>{formatLocation(alert)}</div>
                </div>
              </div>
            );
          })}
        </div>
      </Fragment>
    );
  }
}

ActiveAlerts.propTypes = {
  alert: propTypes.object,
  activeAlerts: propTypes.array,

  onAlertClick: propTypes.func.isRequired,
};

ActiveAlerts.defaultProps = {
  alert: {},
  activeAlerts: [],
};

export default ActiveAlerts;
