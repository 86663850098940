import { createSelector } from 'reselect';
import userSelector from './userSelector';

const DEFAULT_STATE = '';

const loginErrorSelector = createSelector(
  userSelector,
  user => user.loginError || DEFAULT_STATE,
);

export default loginErrorSelector;
