import React, { Component } from 'react';
import propTypes from 'prop-types';

import { alertHelpers } from 'rmwc-helpers';
const { formatCSVAlerts } = alertHelpers;

import TableList from 'modules/components/TableList';
import Store from 'store/store';

class AlertsHistory extends Component {
  componentDidMount() {
    const {
      fetchEnterpriseInactiveAlerts,
      fetchEnterpriseNotes,
    } = this.props;
    fetchEnterpriseInactiveAlerts();
    fetchEnterpriseNotes();
  }

  getTableData() {
    const { inactiveAlertsList = [] } = this.props;

    const tableData = {
      title: 'Alerts History',
      cellDataList: inactiveAlertsList,
      cellDefinitions: [
        {
          header: {
            label: 'ID',
          },
          text: {
            key: 'id',
          },
          link: {
            style: 'text',
            to: '/alerts/history/#KEY#',
            keys: ['uuid'],
            onclick: (url) => {
              Store.history.push(url);
            },
          },
        },
        {
          header: {
            label: 'Status',
          },
          text: {
            key: 'status',
            formatter: data => data.replace('_', ' ').toUpperCase(),
            color: (fornattedData) => {
              if (fornattedData === 'DEACTIVATED') return 'green';
              if (fornattedData === 'ACTIVE') return 'red';
              return 'blue';
            },
          },
        },
        {
          header: {
            label: 'Device',
          },
          text: {
            key: 'firstname',
          },
        },
        {
          header: {
            label: 'Location',
          },
          text: {
            key: 'lastname',
          },
        },
        {
          header: {
            label: 'Date/Time',
            span: 2,
          },
          text: {
            key: 'createdAt',
          },
        },
        {
          text: {
            value: 'view',
          },
          link: {
            to: '/alerts/history/#KEY#',
            keys: ['uuid'],
            style: 'button',
            onclick: (url) => {
              Store.history.push(url);
            },
          },
        },
      ],
    };

    return tableData;
  }

  render() {
    const {
      requesting,
    } = this.props;

    return (
      <TableList
        requesting={requesting}
        data={this.getTableData()}
        download="AlertsHistory"
        csvFormatter={() => {
          const { inactiveAlertsList, allNotes } = this.props;
          return formatCSVAlerts(inactiveAlertsList, allNotes);
        }}
      />
    );
  }
}

AlertsHistory.propTypes = {
  inactiveAlertsList: propTypes.array,
  allNotes: propTypes.object,
  requesting: propTypes.bool,

  fetchEnterpriseInactiveAlerts: propTypes.func.isRequired,
  fetchEnterpriseNotes: propTypes.func.isRequired,
};

AlertsHistory.defaultProps = {
  inactiveAlertsList: [],
  allNotes: {},
  requesting: false,
};

export default AlertsHistory;
