import {
  configureActions,
  isErrorResponse,
  checkNoUpdate,
} from 'store/helpers';

import {
  getEnterpriseLTEDevices,
} from 'lib/api/enterprise';
import {
  lteDevicesMetaSelector,
} from './selectors/lteDevicesSelector';

import { LTE_DEVICES } from './const';

const lteDeviceActions = configureActions(LTE_DEVICES);
export const fetchEnterpriseLTEDevices = opts => async (dispatch, getState) => {
  const {
    etag = '',
    requesting = false,
  } = lteDevicesMetaSelector(getState());

  if (requesting) return;
  dispatch(lteDeviceActions.request());
  const response = await getEnterpriseLTEDevices(opts);

  // Handle the external beacons error differently, we don't want a 401 from external APIs
  // triggering a logout because we treat it as auth expired
  if (isErrorResponse(response)) {
    const { message } = response;
    dispatch(lteDeviceActions.reject(message));
    return;
  }

  if (checkNoUpdate(dispatch, etag, response, lteDeviceActions.end)) return;
  dispatch(lteDeviceActions.receive(response));
};
