import { Logger } from 'rmwc-libraries';

import Sounds from 'lib/sounds';
import Settings from 'lib/settings';

import Store from 'store/store';

class Notifications {
  constructor() {
    this.enabled = !!(window.Notification && window.Notification.requestPermission);
    Logger.debug(`Notifications enabled:${this.enabled}`);

    this.query = null;
    this.onchange = () => {};

    this.activeAlertNotifications = {};
    this.inactiveAlertNotifications = {};

    this.startPermissionWatch();
  }

  get status() {
    if (!this.enabled) return 'unavailable';
    return Notification.permission;
  }

  get granted() {
    return this.status === 'granted';
  }

  request(callback = () => {}) {
    if (!this.enabled || this.granted) return;
    Promise.resolve(Notification.requestPermission()).then((permission) => {
      callback(permission);
    }).catch(() => {
      callback(null);
    });
  }

  send(opts = {}) {
    if (!this.enabled) return null;
    try {
      const { title, body, onclick = () => {} } = opts;
      const note = new Notification(title, { body });
      note.onclick = () => {
        onclick();
        note.close();
      };
      return note;
    } catch (e) {
      this.enabled = false;
      return null;
    }
  }

  onChange(permission) {
    if (typeof this.onchange !== 'function') {
      Logger.debug('Notifications onchange is not set');
      return;
    }
    this.onchange(permission);
  }

  startPermissionWatch() {
    if (!navigator || !navigator.permissions) {
      Logger.debug('Notifications permission watch setup fail');
      return;
    }

    // There is no way to clear these queries. We only want to keep track of
    // one and just change its onchange function
    Logger.debug('Notifications permission watch setup success');
    navigator.permissions.query({ name: 'notifications' }).then((permission) => {
      this.query = permission;
      this.query.onchange = () => {
        this.onChange(permission.state);
      };
    });
  }

  checkActiveNotifications(activeAlertsList) {
    activeAlertsList.forEach((alert) => {
      if (this.activeAlertNotifications[alert.uuid]) return;
      if (Settings.getValue('newAlertNotifications') === false) {
        this.activeAlertNotifications[alert.uuid] = true;
        return;
      }
      this.activeAlertNotifications[alert.uuid] = this.createNotification(true, alert);
      if (Settings.getValue('alertSoundEnabled')) {
        Sounds.alarm({
          repeat: Settings.getValue('alertSoundRepeat'),
        });
      }
    });
  }

  checkInactiveNotifications(inactiveAlertsList) {
    inactiveAlertsList.forEach((alert) => {
      // If we've already fired an inactive notification for this alert, exit
      if (this.inactiveAlertNotifications[alert.uuid]) return;
      // If the alert doesn't exist in the active notifications, exit
      if (!this.activeAlertNotifications[alert.uuid]) return;
      if (Settings.getValue('alertDeactivationNotifications') === false) {
        this.inactiveAlertNotifications[alert.uuid] = true;
        return;
      }
      this.inactiveAlertNotifications[alert.uuid] = this.createNotification(false, alert);
    });
  }

  createNotification(active, alert) {
    const { history } = Store;

    const title = (active) ? 'SOS Alert Activated!' : 'SOS Alert Deactivated!';
    let body = `Device: ${alert.firstname}`;
    body += `\nLocation: ${alert.lastname}`;
    Object.keys(alert.customFields || []).forEach((cf) => {
      body += `\n${cf}: ${alert.customFields[cf]}`;
    });

    return this.send({
      title,
      body,
      onclick: () => {
        const pathChunk = (active) ? 'active' : 'history';
        history.push(`/alerts/${pathChunk}/${alert.uuid}`);
      },
    });
  }
}

export default new Notifications();
