import { connect } from 'react-redux';

import {
  dispatchersListAllSelector,
  dispatchersListRequestingSelector,
} from 'store/dispatchers/selectors/dispatchersSelector';
import {
  dispatcherCreatedSelector,
  createDispatcherMetaSelector,
} from 'store/dispatchers/selectors/dispatcherCreatedSelector';

import {
  fetchDispatchers,
  createDispatcher,
} from 'store/dispatchers/thunks';

import Dispatchers from './components/Dispatchers';

const mapStateToProps = state => ({
  dispatchers: dispatchersListAllSelector(state),
  dispatcherCreated: dispatcherCreatedSelector(state),
  requesting: dispatchersListRequestingSelector(state),
  createMeta: createDispatcherMetaSelector(state),
});

const mapDispatchToProps = {
  fetchDispatchers,
  createDispatcher,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dispatchers);
