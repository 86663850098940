import { connect } from 'react-redux';

import allAlertsSelector from 'store/alerts/selectors/allAlertsSelector';

import {
  fetchDisableAsFalseAlarmAlert,
} from 'store/alerts/active/thunks';
import {
  fetchEnterpriseInactiveAlerts,
} from 'store/alerts/inactive/thunks';

import AlertDetails from './components/AlertDetails';

const mapStateToProps = state => ({
  allAlerts: allAlertsSelector(state),
});

const mapDispatchToProps = {
  fetchEnterpriseInactiveAlerts,
  fetchDisableAsFalseAlarmAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertDetails);
