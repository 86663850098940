import React, { Component } from 'react';
import propTypes from 'prop-types';

import AlertMap from 'modules/components/AlertMap';

import Settings from 'lib/settings';

import ActiveAlerts from './ActiveAlerts';
import AlertDetails from './AlertDetails';

import styles from './Dashboard.module.css';

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAlertUuid: null,
    };
  }

  componentDidMount() {
    this.selectAlert();
  }

  componentDidUpdate() {
    this.selectAlert();
  }

  onAlertClick(uuid) {
    this.setState({
      selectedAlertUuid: uuid,
    });
  }

  getSelectedAlert() {
    const { activeAlertsList } = this.props;
    const { selectedAlertUuid } = this.state;
    if (!activeAlertsList.length) return null;

    let selectedAlert = null;
    activeAlertsList.forEach((alert) => {
      if (alert.uuid === selectedAlertUuid) {
        selectedAlert = alert;
      }
    });

    if (!selectedAlert) return activeAlertsList[0];
    return selectedAlert;
  }

  selectAlert() {
    const { activeAlertsList = [] } = this.props;
    const selectedAlert = this.getSelectedAlert();
    if (!selectedAlert && activeAlertsList.length) {
      this.setState({
        selectedAlertUuid: activeAlertsList[0].uuid,
      });
    }
  }

  render() {
    const { activeAlertsList } = this.props;
    const selectedAlert = this.getSelectedAlert();

    return (
      <div className={styles.wrapper}>
        <AlertMap
          className={styles.mapWrapper}
          alert={selectedAlert}
          alerts={activeAlertsList}
          onMarkerClick={(uuid) => { this.onAlertClick(uuid); }}
          zoomFitNewAlerts={Settings.getValue('zoomFitNewAlerts')}
          zoomOnPinClick={Settings.getValue('zoomOnPinClick')}
        />
        <div className={styles.alertDetails}>
          <AlertDetails alert={selectedAlert} />
        </div>
        <div className={styles.alerts}>
          <ActiveAlerts
            alert={selectedAlert}
            activeAlerts={activeAlertsList}
            onAlertClick={(uuid) => { this.onAlertClick(uuid); }}
          />
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  activeAlertsList: propTypes.array,
};

Dashboard.defaultProps = {
  activeAlertsList: [],
};

export default Dashboard;
