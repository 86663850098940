import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import cssVars from 'css-vars-ponyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';

import Store from 'store/store';

import App from 'modules/components/App';

import './index.scss';
import 'rmwc-components/dist/main.css';

import * as serviceWorker from './serviceWorker';

// For IE11
cssVars({});

ReactDOM.render(
  <Provider store={Store.store}>
    <Router history={Store.history}>
      <App history={Store.history} />
    </Router>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
