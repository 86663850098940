import { connect } from 'react-redux';

import {
  addTableData,
} from 'store/general/thunks';

import {
  tablesSelector,
} from 'store/general/selectors/generalSelector';

import { TableList } from 'rmwc-components';

const mapStateToProps = state => ({
  savedTables: tablesSelector(state),
});

const mapDispatchToProps = {
  addTableData,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableList);
