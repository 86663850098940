import { signInUser } from 'store/user/thunks';

import { connect } from 'react-redux';

import loginErrorSelector from 'store/user/selectors/loginErrorSelector';
import userLoggedInSelector from 'store/user/selectors/userLoggedInSelector';

import { backendUrlSelector } from 'store/general/selectors/generalSelector';

import UserAccess from './components/UserAccess';

const mapStateToProps = state => ({
  loginError: loginErrorSelector(state),
  userLoggedIn: userLoggedInSelector(state),
  backendUrl: backendUrlSelector(state),
});

const mapDispatchToProps = {
  signInUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserAccess);
