import get from 'lodash/get';
import { INFORMATION } from 'store/user/const';
import { LTE_DEVICES } from './const';

const initialState = {
  list: [],
  meta: {
    etag: '',
    requesting: false,
    errorMessage: '',
  },
};

function devicesReducer(state = initialState, action) {
  switch (action.type) {
    case LTE_DEVICES.REQUEST:
      return {
        ...state,
        meta: {
          ...state.meta,
          errorMessage: '',
          requesting: true,
        },
      };

    case LTE_DEVICES.RECEIVE:
      return {
        ...state,
        list: (action.response.data || []).map((device) => {
          return {
            name: get(device, 'shadow.name', ''),
            batteryLife: get(device, 'heartbeat.batteryPercentage', ''),
            updatedAt: get(device, 'heartbeat.lastSynced', ''),
            networkCarrier: get(device, 'heartbeat.networkCarrier', ''),
            appName: get(device, 'iotApp.appName', ''),
            deviceType: 'SaLTE',
          };
        }),
        meta: {
          ...initialState.meta,
          etag: action.response.etag,
        },
      };

    case LTE_DEVICES.REJECT:
      return {
        ...initialState,
        meta: {
          ...initialState.meta,
          errorMessage: action.error,
        },
      };

    case LTE_DEVICES.END:
      return {
        ...state,
        meta: {
          ...state.meta,
          requesting: false,
        },
      };

    case INFORMATION.CLEAR: {
      return initialState;
    }

    default:
      return state;
  }
}

export default devicesReducer;
