import { connect } from 'react-redux';

import {
  activeAlertsListSelector,
  activeAlertsRequestingSelector,
} from 'store/alerts/active/selectors/activeAlertsSelector';
import {
  alertNotesSelector,
} from 'store/alerts/notes/selectors/alertNotesSelector';

import {
  fetchDisableAsFalseAlarmAlert,
} from 'store/alerts/active/thunks';
import {
  fetchEnterpriseNotes,
} from 'store/alerts/notes/thunks';

import ActiveAlerts from './components/ActiveAlerts';

const mapStateToProps = state => ({
  activeAlertsList: activeAlertsListSelector(state),
  allNotes: alertNotesSelector(state),
  requesting: activeAlertsRequestingSelector(state),
});

const mapDispatchToProps = {
  fetchDisableAsFalseAlarmAlert,
  fetchEnterpriseNotes,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveAlerts);
