import React, { Component, Fragment } from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';

import { Button } from 'rmwc-components';
import { Symbols } from 'rmwc-libraries';

import Notifications from 'lib/notifications';
import Sounds from 'lib/sounds';

import styles from './SystemInfo.module.css';

class SystemInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permissionStatus: Notifications.status,
    };

    this.onRequestNotificationPermission = this.onRequestNotificationPermission.bind(this);
    this.onTestDesktopNotification = this.onTestDesktopNotification.bind(this);
    this.onTestAlertSound = this.onTestAlertSound.bind(this);
  }

  componentDidMount() {
    const { fetchVersion } = this.props;
    fetchVersion();

    Notifications.onchange = (permission) => {
      this.setState({
        permissionStatus: permission,
      });
    };
  }

  componentWillUnmount() {
    Notifications.onchange = null;
  }

  onRequestNotificationPermission() {
    Notifications.request((permission) => {
      if (!permission) return;
      this.setState({
        permissionStatus: permission,
      });
    });
  }

  onTestDesktopNotification() {
    Notifications.send({
      title: 'TEST - Dispatch Center Notification',
      body: 'This is a test notification from the React Mobile Dispatch Center',
    });
  }

  onTestAlertSound() {
    Sounds.alarm();
  }

  renderNotifications() {
    if (!Notifications.enabled || Notifications.status === 'unavailable') {
      return (
        <div className={classnames(styles.text, styles.red)}>Desktop Notifications are not available for this browser</div>
      );
    }

    const { permissionStatus } = this.state;
    const npClass = (permissionStatus === 'granted') ? styles.green : styles.red;
    return (
      <Fragment>
        <div className={styles.text}>Your current desktop notifications permission is set to:</div>
        <div className={classnames(styles.value, npClass)}>{permissionStatus}</div>
        <div className={styles.text}>Request Notification Permission</div>
        <div className={styles.details}>
          Some browsers will not show a prompt for desktop notifications without explicit user interaction. If your permissions
          are set to <span className={styles.red}>DEFAULT|PROMPT</span> and and no prompt has appeared, please click the button below.
          The button will be disabled if you have already granted/denied permission.
        </div>
        <Button
          text="Request Permission"
          onClick={this.onRequestNotificationPermission}
          disabled={!!permissionStatus.match(/granted|denied/)}
        />
        <div className={styles.details}>
          If you want to see desktop notifications and your permission setting is <span className={styles.red}>DENIED</span> you will need to clear the notification settings
          for this page in your browser settings. This process will be different between browsers.
        </div>

        <div className={styles.text}>Test Desktop Notifications (no sound effect)</div>
        <Button text="Test Notification" onClick={this.onTestDesktopNotification} />
        <div className={styles.details}>
          If your permission is <span className={styles.green}>GRANTED</span> and you are not seeing test notifications, than a setting in the browser is blocking them.
        </div>
      </Fragment>
    );
  }

  renderVersion() {
    const { version = {} } = this.props;
    const { version: versionNumber } = version;
    if (!versionNumber) return null;
    return (
      <Fragment>
        <div className={styles.text}>Version</div>
        <div className={styles.details}>{versionNumber}</div>
      </Fragment>
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderNotifications()}

        <div className={styles.text}>Test sound effect for new Alerts</div>
        <Button text="Play Sound" onClick={this.onTestAlertSound} />

        <div className={styles.text}>The alarm sound effect for incoming alerts is used under a Creative Common license.</div>
        <a className={classnames(styles.value, styles.link)} href="http://soundbible.com/1377-Woop-Woop.html" target="_blank" rel="noopener noreferrer">More information is available here</a>

        <div className={styles.text}>{Symbols.copyright}{new Date().getFullYear()} React Mobile, INC.</div>
        <a className={classnames(styles.value, styles.link)} href="https://www.reactmobile.com" target="_blank" rel="noopener noreferrer">React Mobile Website</a>

        {this.renderVersion()}
      </Fragment>
    );
  }
}

SystemInfo.propTypes = {
  version: propTypes.object,

  fetchVersion: propTypes.func.isRequired,
};

SystemInfo.defaultProps = {
  version: {},
};

export default SystemInfo;
