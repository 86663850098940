import React, { Component } from 'react';
import { Form } from 'rmwc-components';

import Settings, { SETTINGS_SCHEMA } from 'lib/settings';

class ApplicationSettings extends Component {
  constructor(props) {
    super(props);

    Settings.initialize();

    this.submitFormData = this.submitFormData.bind(this);
  }

  getFormData() {
    const formData = {};
    Object.keys(SETTINGS_SCHEMA).forEach((setting) => {
      formData[setting] = {
        value: Settings.getValue(setting),
        label: SETTINGS_SCHEMA[setting].label,
        type: SETTINGS_SCHEMA[setting].type,
      };
    });
    return formData;
  }

  submitFormData(data) {
    Object.keys(data).forEach((settingsKey) => {
      Settings.setValue(settingsKey, Boolean(data[settingsKey]));
    });
    return 'success';
  }

  render() {
    return (
      <Form
        fieldInfo={this.getFormData()}
        onSubmit={this.submitFormData}
      />
    );
  }
}

ApplicationSettings.propTypes = {};

export default ApplicationSettings;
