import { getUser, signOut } from 'lib/api/user';

import {
  configureActions,
  checkError,
} from 'store/helpers';

import { INFORMATION } from './const';

const userInformationActions = configureActions(INFORMATION);
export const signInUser = ({ email, password }) => async (dispatch) => {
  dispatch(userInformationActions.request(email));
  const response = await getUser({ email, password });
  if (checkError(dispatch, response, userInformationActions.reject, {
    ignoreAuth401: true,
  })) return 'fail';
  dispatch(userInformationActions.receive(response));
  return 'success';
};

export const signOutUser = () => async (dispatch) => {
  dispatch(userInformationActions.clear());
  signOut();
};

export const addUserData = user => async (dispatch) => {
  dispatch(userInformationActions.receive(user));
};
